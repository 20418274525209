import React from "react";
import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { rightArrow } from "../../utils/svg.file";
function Content({ alertMessageContent, colorAlert }) {
  return (
    <>
      <div className="alert_message">
        <p className={`fw-bold ${colorAlert}`}>{alertMessageContent}</p>
      </div>
    </>
  );
}

export default Content;
