import React from "react";
import PropTypes from "prop-types";
import Content from "./content";
import Image from "./image";
import { Grid } from "@mui/material";

function EventCard(props) {
  return (
    <>
      <Grid
        container
        spacing={6}
        data-aos="fade-up"
        data-aos-duration="3000"
        className="event-card-small"
      >
        <Grid item md={6} className="pt-0">
          <Image {...props} />
        </Grid>{" "}
        <Grid item md={6} className="pt-0">
          <Content {...props} />
        </Grid>
      </Grid>
    </>
  );
}

EventCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string,
  descriptionTitle: PropTypes.string,
};

export default EventCard;
