import { React, useState, useEffect } from "react";
import SubHeader from "../../components/layout/SubHeader";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import "react-multi-carousel/lib/styles.css";
import BannerSlider from "../../components/BannerSlider";
import TabButton from "../Celebration_&_Event/TabButton";
import JobCard from "../../components/jobCard";
import JobModal from "../../components/jobModal";
import { showjob, showjobDiscription } from "../../api/frontend";
import { useLocation, useNavigate } from "react-router-dom";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";

export default function JobOpportunity() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [jobDetailModal, setJobDetailModal] = useState(false);
  const [showDiscription, setDiscription] = useState("");
  const [country, setCountry] = useState(1);
  const [jobArray, setJobArray] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [jobcategoryData, setJobcategoryData] = useState([]);
  const [joblocationData, setJoblocationData] = useState([]);
  const [jobdesignationData, setJobdesignationData] = useState([]);
  const [isLoadingData, setIslodingData] = useState(true);

  const handleJobDetailShow = ({
    discription,
    category,
    location,
    designation,
  }) => {
    setJobData(discription);
    setJobcategoryData(category);
    setJoblocationData(location);
    setJobdesignationData(designation);
    setJobDetailModal(true);
  };
  const handleJobDetailHide = () => {
    setJobDetailModal(false);
  };

  const { jobBanner } = IMAGES;
  const BannerArray = [jobBanner];
  const location = useLocation();
  const navigate = useNavigate();
  const handleJobsDiscription = async () => {
    setIslodingData(true);
    const response = await showjobDiscription();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setDiscription(response.data.data);
    } else {
    }
  };
  const handleJobs = async () => {
    setIslodingData(true);
    const response = await showjob();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setJobArray(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleJobs();
    handleJobsDiscription();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{showDiscription.meta_title}</title>
        <meta name="description" content={showDiscription.meta_description} />
        <meta name="keywords" content='resort careers, restaurant jobs, hospitality employment, culinary positions, resort management jobs, food and beverage careers, guest services opportunities, housekeeping jobs, event planning positions in KL' />
        <link rel="canonical" href="https://www.samadhiretreats.com/job-opportunity"/>
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}

      <div className="job-apply">
        {" "}
        <div className="main-bg">
          <Container maxWidth="xl">
            <div className="sub-header-div">
              <SubHeader
                pageTitle="Job Opportunities"
                // subHeading={[{ id: "0", subLink: "", subUrl: "#" }]}
              />
            </div>
          </Container>
          <Container maxWidth="xl" className="banner_container">
            <div
              className="banner-div"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <h2 className="text-center ">Careers with Samadhi</h2>
              <img src={jobBanner} alt="" />
            </div>
          </Container>
          <Container maxWidth="xl">
            <div className="page-description ">
              <Grid container justifyContent="center" className=" ">
                <Grid
                  item
                  md={6}
                  xl={6}
                  className="page-description-data text-center"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <h3 className="geting-text">Our Culture</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: showDiscription.discription,
                    }}
                  />
                </Grid>
              </Grid>
            </div>{" "}
          </Container>
          <div className="vacancies-section ">
            <Container maxWidth="xl">
              <Grid
                container
                spacing={2}
                className="py-200"
                justifyContent="center"
              >
                <Grid item md={10} xs={12}>
                  <div className="text-center">
                    <h3 className="geting-text">Vacancies</h3>
                  </div>
                  <div className="">
                    <div className="tab-button">
                      <Container>
                        <div className="justify-content-md-center">
                          <div className="slider-count-text text-center">
                            <span
                              className={
                                Number(country) === 1
                                  ? "active cursor-btn"
                                  : "cursor-btn"
                              }
                              onClick={() => setCountry(1)}
                            >
                              Malaysia{" "}
                            </span>{" "}
                            {/* /{" "}
                            <span
                              onClick={() => setCountry(2)}
                              className={
                                Number(country) === 1
                                  ? "cursor-btn"
                                  : "active cursor-btn"
                              }
                            >
                              {" "}
                              Singapore
                            </span> */}
                          </div>
                        </div>
                      </Container>
                    </div>
                  </div>
                  <div className="job-apply-card">
                    {jobArray.map((item, index) => {
                      return (
                        Number(item.state) === Number(country) && (
                          <JobCard
                            {...item}
                            key={index}
                            handleJobDetailShow={handleJobDetailShow}
                          />
                        )
                      );
                    })}
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </div>
      <JobModal
        handleJobDetailHide={handleJobDetailHide}
        handleJobDetailShow={handleJobDetailShow}
        jobDetailModal={jobDetailModal}
        jobData={jobData}
        jobcategoryData={jobcategoryData}
        joblocationData={joblocationData}
        jobdesignationData={jobdesignationData}
      />
    </>
  );
}
