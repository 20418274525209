import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { rightArrow, subMenuArrow } from "../../utils/svg.file";
import { Button } from "react-bootstrap";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
const subHeading = [
  "Gallery",
  "Stays",
  "Tastes",
  "Stories",
  "Wellness",
  "Celebrations",
  "Gift Card",
  "Offers",
];
export default function SubHeader(props) {
  const navigate = useNavigate();
  const [pageName, setPageName] = React.useState("");

  const handleChange = (event) => {
    setPageName(event.target.value);
  };
  const location = useLocation();
  return (
    <>
      <div className="sub-header">
        <Row className="g-0">
          <Col
            lg={6}
            className="d-flex justify-content-md-end  justify-content-sm-center sub-header-title"
          >
            <h3>
              {/* <Select
                value={pageName}
                onChange={handleChange}
                displayEmpty
                readOnly={
                  location.pathname === "/villa-samadhi-kuala-lumpur/rooms" ||
                  location.pathname === "/fine-dining-restaurants-kuala-lumpur-singapore" ||
                  location.pathname === "/villa-samadhi-kuala-lumpur" ||
                  location.pathname === "/villa-samadhi-kl-accomodation-crib" ||
                  location.pathname === "/villa-samadhi-kuala-lumpur/gallery"
                    ? false
                    : true
                }
                className="sub-header-select"
              >
                <MenuItem value="">{props.pageTitle}</MenuItem>
                <MenuItem value={10}>Singapore</MenuItem>
                <MenuItem value={20}>Page Two</MenuItem>
                <MenuItem value={30}>Page Three</MenuItem>
              </Select> */}
              {props.pageTitle}
              <span className="ms-2">{subMenuArrow}</span>
            </h3>
          </Col>
          <Col lg={6}>
            <ul className="ps-0 pt-20 ">
              {props.subHeading?.map((item, index) => (
                <li key={index}>
                  {/* <Link to="#" className="active">{item.subLink}</Link> */}
                  <Button
                    onClick={() =>
                      navigate(
                        item.id
                          ? `${item.subUrl}?catagoryId=${item.id}`
                          : `${item.subUrl}`
                      )
                    }
                    className={
                      Number(props.categoryId) === Number(item.id)
                        ? "active"
                        : ""
                    }
                  >
                    {item.subLink}
                  </Button>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </div>
    </>
  );
}
