import React from "react";
import PropTypes from "prop-types";
import Content from "./content";
import { Button, Grid } from "@mui/material";

function JobCard(props) {
  return (
    <>
      <div className="job-card-flex">
        <Content {...props} />
      </div>
    </>
  );
}

JobCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default JobCard;
