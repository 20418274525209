import {React,useEffect} from "react";
import SubHeader from "../../components/layout/SubHeader";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import TestimonialSlider from "../../components/TestimonalSlider";
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"; 
import { Link, useNavigate } from "react-router-dom";
import { showExperienceList } from "../../api/frontend";
import { useState } from "react";
import { Offers_URL } from "../../serverUrl";
import { Helmet } from "react-helmet";

const {
  travelBanner,
  motorBikeOne,
  motorBikeTwo,
  motorBikeThree,
  motorBikeFour,
  dayTripOne,
  dayTripTwo,
  dayTripThree,
  experience1,
} = IMAGES;
const dayTrips = [
  {
    image: dayTripOne,
    type: "DAYTRIPS",
    title: "Fraser Hill",
    description:
      "Set among the leafy groves of KL’s affluent embassy district, Villa Samadhi is the perfect urban escape… ",
    id: "1",
    speed: "1000",
    dataspeed: "fade-up",
  },
  {
    image: dayTripTwo,
    type: "SUV TOURS",
    title: "12-Days Heritage Tour",
    description:
      "Set among the leafy groves of KL’s affluent embassy district, Villa Samadhi is the perfect urban escape… ",
    id: "2",
    speed: "1000",
    dataspeed: "fade-down",
  },
  {
    image: dayTripThree,
    type: "CRUISES",
    title: "5 days peninsular",
    description:
      "SOMA is your source of wellness. A health system that embraces a holistic approach to total healing of mind, body and soul through a combination of ancient yogic practices… ",
    id: "3",
    speed: "2000",
    dataspeed: "fade-up",
  },
  {
    image: dayTripOne,
    type: "DAYTRIPS",
    title: "Fraser Hill",
    description:
      "Set among the leafy groves of KL’s affluent embassy district, Villa Samadhi is the perfect urban escape… ",
    id: "4",
    speed: "3000",
    dataspeed: "fade-down",
  },
];
export default function TravelCategory() {
  const navigate = useNavigate();
  // window.addEventListener("scroll", () => {
  //   let elem = document.getElementById("keyvisual");
  //   let scrollY = window.scrollY / 10;
  //   elem.style.backgroundSize = 100 + scrollY + "%";
  // });
  const MotorBikeArray = [
    {
      image: motorBikeOne,
      type: "MOTORBIKE TOURS",
      title: "10-Days Kaleidescope Of Colour",
      description:
        "Enjoy a cultural 10 day ride through pathways ranging from the mountainous jungles of the north to the coral reefs of the tropical south. Stand under the canopy of the 130 million year old Belum rainforest and retrace the steps of the Dutch, Portuguese and English by visiting the UNESCO World Heritage Sites.",
      id: "1",
      speed: "1000",
      dataspeed: "fade-up",
    },
    {
      image: motorBikeTwo,
      type: "MOTORBIKE TOURS",
      title: "10-Days Kaleidescope Of Colour",
      description:
        "Enjoy a cultural 10 day ride through pathways ranging from the mountainous jungles of the north to the coral reefs of the tropical south. Stand under the canopy of the 130 million year old Belum rainforest and retrace the steps of the Dutch, Portuguese and English by visiting the UNESCO World Heritage Sites. ",
      id: "2",
      speed: "1000",
      dataspeed: "fade-up",
    },
    {
      image: motorBikeThree,
      type: "MOTORBIKE TOURS",
      title: "10-Days Kaleidescope Of Colour",
      description:
        "Enjoy a cultural 10 day ride through pathways ranging from the mountainous jungles of the north to the coral reefs of the tropical south. Stand under the canopy of the 130 million year old Belum rainforest and retrace the steps of the Dutch, Portuguese and English by visiting the UNESCO World Heritage Sites. ",
      id: "3",
      speed: "2000",
      dataspeed: "fade-up",
    },
    {
      image: motorBikeFour,
      type: "MOTORBIKE TOURS",
      title: "10-Days Kaleidescope Of Colour",
      description:
        "Enjoy a cultural 10 day ride through pathways ranging from the mountainous jungles of the north to the coral reefs of the tropical south. Stand under the canopy of the 130 million year old Belum rainforest and retrace the steps of the Dutch, Portuguese and English by visiting the UNESCO World Heritage Sites.",
      id: "4",
      speed: "3000",
      dataspeed: "fade-up",
    },
  ];
  const experienceArray = [
    {
      image: experience1,
      title: "Shape by nature",
      description:
        "A inconspicuous wooden gate, just off KL’s embassy row marks the entrance to one of KL’s best hidden secrets - Villa Samadhi. As guests pass through the modest entrance, the grounds open up revealing an urban oasis",
      id: "1",
    },
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
      
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [experienceListArray, SetExperienceListArray] = useState([]);
const handleExperienceList = async () => {
  const response = await showExperienceList();

  if (response.remote === "success") {
    SetExperienceListArray(response.data.data);
  } else {
  }
};

useEffect(() => {
  handleExperienceList()
}, [])
var experienceSlide = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,

  slidesToShow: 1,
  slidesToScroll: 1,
};
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Travels & Tours | Exploration, Adventure, Discovery | Samadhi Retreats
        </title>
        <meta
          name="description"
          content="Experience the world differently with Samadhi Trails offbeat adventures. Our unique collection of tours is crafted for like-minded Samadhians like you."
        />
      </Helmet>
      <div className="travel-page travel-motor-bike">
        <div className="room-type-page main-bg">
          <SubHeader
            pageTitle="Travels"
            subHeading={[
              {
                id: "0",
                subLink: "Motorbike tours",
                subUrl: "/travels-motorbike-tours-packages",
              },
              { id: "1", subLink: "SUV tours", subUrl: "#" },
              { id: "2", subLink: "Cruises", subUrl: "#" },
              { id: "3", subLink: "Daytrips", subUrl: "#" },
              { id: "4", subLink: "Experiential Journeys", subUrl: "#" },
              { id: "5", subLink: "Fact about Malaysia", subUrl: "#" },
            ]}
          />
          <Container maxWidth="xl" className="banner_container">
            <div
              className="banner-div"
              // data-aos-once="true"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <img src={travelBanner} alt="" />
            </div>
          </Container>
          <div className="motor-bike-section ">
            <Container maxWidth="xl">
              <div className="normal-card-section pb-120">
                <Grid className="text-center section-head">
                  <h3 className="geting-text">Motorbike Tour</h3>
                </Grid>
                <Grid container spacing={4}>
                  {MotorBikeArray.map((content) => {
                    return (
                      <Grid
                        xs={12}
                        item
                        md={6}
                        className="normal-card-div"
                        key={content.id}
                        data-aos-once="true"
                        data-aos={content.dataspeed}
                        data-aos-duration={content.speed}
                        onClick={() =>
                          navigate("/travels-motorbike-tours-packages-enquiry")
                        }
                      >
                        <OfferSlider {...content} />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Container>
          </div>
          <div className="day-trips-section ">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3 className="geting-text">Daytrips</h3>
              </Grid>
              <div className="btn-slider-div">
                <Carousel
                  autoPlaySpeed={1200}
                  arrows={true}
                  ssr={true}
                  partialVisbile={true}
                  sliderClass="centerslider"
                  responsive={responsive}
                  autoPlay={false}
                  infinite={true}
                  className="mt-100"
                >
                  {dayTrips.slice(0, 5).map((item, index) => (
                    <div
                      key={index}
                      className="bottom-cards-div "
                      data-aos-once="true"
                      data-aos={item.dataspeed}
                      data-aos-duration={item.speed}
                    >
                      <OfferSlider {...item} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </Container>
          </div>

          <div className="experience-div-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3>Stories</h3>
              </Grid>
              <Slider {...experienceSlide} className="experience-slider">
                {experienceListArray?.map((item, index) => (
                  <Grid className="testimonial-type-card">
                    <Grid
                      container
                      spacing={{ md: 6, xs: 2 }}
                      className="align-items-center d-flex "
                      data-aos="fade-up"
                      data-aos-once="true"
                      data-aos-duration="10000"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={4}>
                        <div>
                          {" "}
                          <Grid className="cards heading">
                            <h4>{item.title}</h4>
                            <p>{item.shortdescription}</p>
                            <Link to="#">Discover</Link>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <img src={`${Offers_URL}${item.banner}`} alt="" />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Slider>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
