import React from "react";
import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { rightArrow } from "../../utils/svg.file";
function Content({ handleJobDetailShow,jobData,jobcategoryData,joblocationData,jobdesignationData}) {
  return (
    <>
      <div className="job-detail-page">
        <p>{jobdesignationData}</p>
        <p> Location: {joblocationData}</p>
        <p> Job Description</p>
        <p> {jobcategoryData}</p>
        <p>
        <div
      dangerouslySetInnerHTML={{__html: jobData}}
    />
        </p>
       
      </div>
    </>
  );
}

export default Content;
