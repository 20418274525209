import {React,useEffect} from 'react'
import { Grid } from '@mui/material'
import { Container } from '@mui/material'
import Slider from 'react-slick'
import IMAGES from '../../../assets/images'
import SubHeader from '../../../components/layout/SubHeader'
import BannerSlider from '../../../components/BannerSlider'
import OfferSlider from '../../../components/OfferSlider'
import TestimonialSlider from '../../../components/TestimonalSlider'
import TabButton from '../TabButton'
import EventCard from '../EventCard'
import { celebrationdetails, showfeaturedstory } from '../../../api/frontend'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Featured_URL, Offers_URL } from "../../../serverUrl";
import LoaderMain from "../../../admin/component/loader";
import { Helmet } from 'react-helmet'
import { Celebration_URL } from '../../../serverUrl'
const {
  celebrateOne,
  stayBanner,
  vsklBanner,
  experience7,
  experience6,
  EventOne,
  EventTwo,
} = IMAGES;

export default function TamarindHillSingapore() {
  // const BannerArray = [celebrateOne, stayBanner, vsklBanner];
  const [BannerArray, setBannerArray] = useState([]);
  const [experienceArray, SetexperienceArray] = useState([]);
  const [celebrationData, setCelebrationData] = useState([]);
  const [isLoadingData, setIslodingData] = useState(true);

  let { id } = useParams();
  const eventArray =[];
  celebrationData.final?.map((item, index) => {
    eventArray.push({
      image: `${Celebration_URL}${item.image}`,
      title: item.title,
      description: item.discription,
      id: "1",
      linkUrl: `/celebration-and-events-enquiry?catagoryId=${celebrationData.title}&id=${id}`,
     
    });
  });
  // const eventArray = [
  //   {
  //     image: `${Offers_URL}${celebrationData.first_image}`,
  //     title: celebrationData.first_title,
  //     description: celebrationData.first_description,
  //     id: "1",
  //     linkUrl: `/celebration-and-events-enquiry?catagoryId=${celebrationData.title}&id=${id}`,
  //   },
  //   {
  //     image: `${Offers_URL}${celebrationData.second_image}`,
  //     title: celebrationData.second_title,
  //     description: celebrationData.second_description,
  //     id: "2",
  //     linkUrl: `/celebration-and-events-enquiry?catagoryId=${celebrationData.title}&id=${id}`,
  //   },
  // ];
  var experienceSlide = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
  };
  var exclusiveSlider = {
    arrow: true,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const handlecelebrationdetails = async () => {
    setIslodingData(true);
    const showexclusivePayload = {
      slug: id,
    };
    const response = await celebrationdetails(showexclusivePayload);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);

      setBannerArray(JSON.parse(response.data.data[0].image));
      setCelebrationData(response.data.data[0]);
    } else {
    }
  };
  const handlefeaturedstory = async () => {
    setIslodingData(true);

    const response = await showfeaturedstory();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);

      SetexperienceArray(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handlefeaturedstory();
    handlecelebrationdetails();
    window.scrollTo(0, 0);
  }, [id]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{celebrationData.meta_title}</title>
        <meta name="description" content={celebrationData.meta_description} />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}

      <div className="tamarind-celebration celebrate-singapore-page event_block_page">
        <div className=" main-bg">
          <Container maxWidth="xl">
            <div className="sub-header-div mb-4">
              <SubHeader
                pageTitle="Celebrations & Events"
                // subHeading={[
                //   {
                //     id: "0",
                //     subLink: "Malaysia",
                //     subUrl: "/weddings-events-malaysia",
                //   },
                //   {
                //     id: "1",
                //     subLink: "Singapore",
                //     subUrl: "/weddings-events-singapore",
                //   },
                // ]}
              />
            </div>
          </Container>
          <Container maxWidth="xl" className="banner_container">
            <div className="banner-div">
              <h2
                className="text-center"
                // data-aos="fade-up"
                // data-aos-once="true"
                // data-aos-duration="1500"
              >
                {celebrationData.title}
              </h2>
              <div data-aos="fade-up" data-aos-duration="3000">
                <BannerSlider BannerArray={BannerArray} />
              </div>
            </div>
          </Container>

          <div className="celebration_cards_main ">
            <Container maxWidth="xl">
              <div className="mt-4">{/* <TabButton /> */}</div>
              <div className=" pt-20  event-card-list ">
                {eventArray.map((item, index) => {
                  return (
                    <div className="event-card">
                      {" "}
                      <EventCard {...item} key={index} />
                    </div>
                  );
                })}
              </div>
            </Container>
          </div>

          <div className="experience-div-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3>Stories</h3>
              </Grid>

              <Slider
                {...experienceSlide}
                className="experience-slider celebrate-customer-div"
              >
                {experienceArray.map((item, index) => (
                  <Grid className="testimonial-type-card">
                    <Grid
                      container
                      spacing={{ md: 6, xs: 2 }}
                      className="align-items-center d-flex "
                      data-aos="fade-up"
                      data-aos-duration="10000"
                      data-aos-once="true"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={4}>
                        <div>
                          {" "}
                          <Grid className="cards heading">
                            <h3>{item.discription}</h3>
                            <h3
                              className="mt-5"
                              style={{ fontStyle: "normal" }}
                            >
                              {item.heading}
                            </h3>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <img src={`${Featured_URL}${item.image}`} alt="" />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Slider>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
