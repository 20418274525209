import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import routes from "../../routes";
import FooterSection from "./FooterSection";
import SideMenu from "./SideMenu";
import IMAGES from "../../assets/images";
import { menuBar } from "../../utils/svg.file";
import linedot from "../../assets/images/linedot.png";
import { LocationCity } from "@mui/icons-material";
import { Divider, Dropdown, Space } from "antd";
import { setTotalList } from "../../api/frontend";
import { Container } from "@mui/material";
import ErrorPage from "../../errorPage";
const { whiteLogo, logoPic } = IMAGES;
const drawerWidth = 240;
const navItems = ["Reservation"];

// const items = [
//   {
//     key: "1",
//     label: (
//       <a
//         target="_blank"
//         href="https://www.antgroup.com"
//       >
//         1st menu item
//       </a>
//     ),
//   },
//   {
//     key: "2",
//     label: (
//       <a
//         target="_blank"
//         href="https://www.aliyun.com"
//       >
//         2nd menu item
//       </a>
//     ),
//   },
//   {
//     key: "3",
//     label: (
//       <a
//         target="_blank"
//         href="https://www.luohanacademy.com"
//       >
//         3rd menu item
//       </a>
//     ),
//   },
// ];

function Layout(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [headerFix, setHeaderFix] = useState("");
  const [closable, setClosable] = useState(false);
  const handleDrawerToggle = () => {
    if (!closable) setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box
      // onClick={handleDrawerToggle}
      sx={{ textAlign: "center" }}
    >
      <SideMenu
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
        setClosable={setClosable}
      />
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const changeHeaderOnScroll = (e) => {
    const scrollTop = e.target.scrollingElement.scrollTop;
    if (scrollTop > 50) {
      setHeaderFix("headerSmall");
    } else {
      setHeaderFix("");
    }
  };

  // window.addEventListener('scroll', changeHeaderonScroll);
  React.useEffect(() => {
    document.addEventListener("scroll", changeHeaderOnScroll);
    return () => {
      document.removeEventListener("scroll", changeHeaderOnScroll);
    };
  }, []);
  const location = useLocation();
  const [retreatName, setRetreatName] = useState([]);

  const handleRetreatName = async () => {
    const response = await setTotalList();

    if (response.remote === "success") {
      setRetreatName(response.data.data);
    } else {
    }
  };
  React.useEffect(() => {
    handleRetreatName();
  }, []);
  const items = [];

  retreatName?.map((item, index) => {
    items.push({
      key: item.value,
      label: (
        <>
          <a target="_blank" href={item.booking}>
            {item.label}, {item.other}
          </a>
        </>
      ),
    });
  });

  const [visible, setVisible] = useState(false);

  const handleButtonClick = () => {
    setVisible(!visible);
  };

  const handleMenuClick = (e) => {
    if (e.key === "logout") {
      // Perform logout action
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        {location.pathname === "/accolades" ||
        location.pathname === "/privacy-notes" ||
        location.pathname === "/sustainability" ||
        location.pathname === "/samadhi-experience" ||
        location.pathname === "/experiences-details" ||
        location.pathname ===
          "/experiences-villa-samadhi-kl-thaipusam-experience" ||
        location.pathname === "/experiences-villa-samadhi-sg-lifeatthepark" ? (
          <>
            <AppBar
              component="nav"
              className={`${headerFix} navbar-main main-white-header`}
            >
              <Container maxWidth="xl">
                <Toolbar
                  className="px-0"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <IconButton
                    className="menu-btn-div"
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { lg: "block" } }}
                  >
                    {/* <span>{menuBar}</span> */}
                    <img src={linedot} alt="" />
                  </IconButton>
                  <Typography sx={{ display: { xs: "none", sm: "block" } }}>
                    <Link to="/">
                      {" "}
                      <img src={logoPic} alt="" className="logo-header" loading="lazy" width={185} height={50} priority={false} style={{height:"auto"}} />
                    </Link>
                  </Typography>
                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    {navItems.map((item, index) => (
                      <>
                        <Box>
                          <Dropdown
                            overlay={items}
                            trigger={["click"]}
                            visible={visible}
                            onVisibleChange={setVisible}
                            menu={{
                              items,
                            }}
                            dropdownRender={(menu) => (
                              <>
                                <div className="dropdown_menu_new">
                                  <div>
                                    {React.cloneElement(menu, {})}
                                    <h6>Retreats</h6>
                                    {retreatName?.map((item, index) => {
                                      return (
                                        item.status === "retreat" && (
                                          <>
                                            <a
                                              target="_blank"
                                              href={item.booking}
                                            >
                                              {item.label}, {item.other}
                                            </a>
                                            <br />
                                          </>
                                        )
                                      );
                                    })}
                                  </div>
                                  <div className="mt-5">
                                    {React.cloneElement(menu, {})}
                                    <h6>Restaurants</h6>
                                    {retreatName?.map((item, index) => {
                                      return (
                                        item.status === "restaurant" && (
                                          <>
                                            <a
                                              target="_blank"
                                              href={item.booking}
                                            >
                                              {item.label}, {item.other}
                                            </a>

                                            <br />
                                          </>
                                        )
                                      );
                                    })}
                                  </div>
                                </div>
                              </>
                            )}
                            placement="bottomLeft"
                          >
                            <Button
                              key={index}
                              sx={{ color: "#fff", fontSize: "21px" }}
                              className="reserve-head px-0"
                            >
                              {item}
                            </Button>
                          </Dropdown>
                        </Box>
                      </>
                    ))}
                  </Box>
                </Toolbar>
              </Container>
            </AppBar>
          </>
        ) : (
          <AppBar
            component="nav"
            className={`${headerFix} navbar-main brown-back`}
          >
            <Container maxWidth="xl">
              <Toolbar
                className="px-0"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <IconButton
                  className="menu-btn-div"
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { lg: "block" } }}
                >
                  {/* <MenuIcon/> */}
                  <img src={linedot} alt="" loading="lazy" width={22} height={16} />
                </IconButton>
                <Typography sx={{ display: { xs: "none", sm: "block" } }}>
                  <Link to="/">
                    {" "}
                    <img src={whiteLogo} alt="" className="logo-header" loading="lazy" width={185} height={50} priority={false} style={{height:"auto"}} />
                  </Link>
                </Typography>
                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                  {navItems.map((item, index) => (
                    <>
                      <Box>
                        <Dropdown
                          overlay={items}
                          trigger={["click"]}
                          visible={visible}
                          onVisibleChange={setVisible}
                          menu={{
                            items,
                          }}
                          dropdownRender={(menu) => (
                            <>
                              <div className="dropdown_menu_new">
                                <div>
                                  {React.cloneElement(menu, {})}
                                  <h6>Retreats</h6>
                                  {retreatName?.map((item, index) => {
                                    return (
                                      item.status === "retreat" && (
                                        <>
                                          <a
                                            target="_blank"
                                            href={item.booking}
                                          >
                                            {item.label}, {item.other}
                                          </a>

                                          <br />
                                        </>
                                      )
                                    );
                                  })}
                                </div>
                                <div className="mt-4">
                                  {React.cloneElement(menu, {})}
                                  <h6>Restaurants</h6>
                                  {retreatName?.map((item, index) => {
                                    return (
                                      item.status === "restaurant" && (
                                        <>
                                          <a
                                            target="_blank"
                                            href={item.booking}
                                          >
                                            {item.label}, {item.other}
                                          </a>

                                          <br />
                                        </>
                                      )
                                    );
                                  })}
                                </div>
                              </div>
                            </>
                          )}
                          placement="bottomLeft"
                        >
                          <Button
                            key={index}
                            sx={{ color: "#fff", fontSize: "21px" }}
                            className="reserve-head px-0"
                          >
                            {item}
                          </Button>
                        </Dropdown>
                      </Box>
                    </>
                  ))}
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
        )}

        <Box component="nav">
          <Drawer
            // data-aos="zoom-in"
            className="zoom-in"
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", lg: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box component="main">
          <Toolbar />
          <Grid className="main-page-content">
            <Routes>
              {routes.map((route, index) => {
                return (
                  <Route
                    path={route.path}
                    element={route.component}
                    key={index}
                  />
                );
              })}

              {/* <Route path="*" element={<Error />} /> */}
              {/* <Route path="*" exact={true} element={<ErrorPage />} /> */}
            </Routes>
          </Grid>
        </Box>
      </Box>{" "}
      <Grid sx={{ BackgroundColor: "#fff", px: 5 }} className="mobile_footer">
        <FooterSection />
      </Grid>{" "}
      <div className=" footer-last"></div>
    </>
  );
}

Layout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Layout;
