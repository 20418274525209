import React, { useState } from "react";
import PropTypes from "prop-types";
import Image from "./image";

import { Container } from "@mui/material";
import Slider from "react-slick";
import { Offers_URL } from "../../serverUrl";
import Carousel from "react-multi-carousel";

function BannerSlider(props) {
  // state
  const [sliderIndex, setSliderIndex] = useState(1);

  var bannerSetting = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    afterChange: (indexOfCurrentSlide) => {
      setSliderIndex(indexOfCurrentSlide + 1);
    },
  };
  // const CustomRightArrow = ({ onClick, ...rest }) => {
  //   const {
  //     onMove,
  //     carouselState: { currentSlide, deviceType },
  //   } = rest;
  //   // onMove means if dragging or swiping in progress.
  //   return <button onClick={() => alert("tet")} />;
  // };

  // ref
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };
  return (
    <div>
      <div
        className=" banner-slider-card btn-slider-div"
        transition="fade-up"
        delay="2000"
        data-aos-once="true"
      >
        {/* <Slider {...bannerSetting}> */}
        <Carousel
          autoPlaySpeed={1200}
          arrows={true}
          responsive={responsive}
          autoPlay={false}
          infinite={true}
        >
          {props.BannerArray?.map((img, index) => {
            return (
              <div className="" key={index}>
                <Image image={`${Offers_URL}${img.image ? img.image : img}`} />
              </div>
            );
          })}
        </Carousel>

        {/* <div className="number-slider">
          <Container>
            <div className="justify-content-md-center">
              <div className="slider-count-text text-center">
                <span>{sliderIndex} </span> /
                <span> {props.BannerArray?.length}</span>
              </div>
            </div>
          </Container>
        </div> */}
      </div>
    </div>
  );
}

BannerSlider.propTypes = {
  image: PropTypes.string,
};

export default BannerSlider;
