import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";
import IMAGES from "../../assets/images";
import { faceBook, instagram, youtube } from "../../utils/svg.file";
import { Form } from "react-bootstrap";
import { addNewsLetter, footerSection, restaurantNameList, RetreatCategorySection, showStoryTitle, TravelCategorySection } from "../../api/frontend";
import { useState } from "react";
import swal from "sweetalert";
const { logoPic, footerLogo,FooterNew } = IMAGES;
const moreInformation = [
  { id: "0", title: "About us", url: "/about-us" },
  { id: "1", title: " Sustainability", url: "/sustainability" },
  {
    id: "2",
    title: "Celebrations &  events ",
    url: "/weddings-events-kuala-lumpur",
  },
  { id: "3", title: "Accolades ", url: "/accolades" },
  // { id: "4", title: "New developments", url: "" },
  { id: "5", title: "Job opportunities", url: "/job-opportunity" },
  { id: "6", title: "Privacy notice", url: "/privacy-notes" },
  // { id: "7", title: "Contact us", url: "" },
];

const Travels = [
  { id: "0", title: "Cruises", url: "/samadhi-cruises" },
  { id: "1", title: " Days trips", url: "#" },
  { id: "2", title: "Experiential travel", url: "#" },
  {
    id: "3",
    title: " Motorbike tour",
    url: "/travels-motorbike-tours-packages",
  },
];
const Experiences = [
  { id: "0", title: "Samadhian", url: "/samadhi-experience" },
  { id: "1", title: " Celebrations", url: "/samadhi-experience" },
  { id: "2", title: "Culture", url: "/samadhi-experience" },
  { id: "3", title: "Discover", url: "/samadhi-experience" },
  { id: "4", title: "Wellness", url: "/samadhi-experience" },
];
export default function FooterSection() {
  const location = useLocation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [restaurantName, setRestaurantName] = useState([]);
  const [retreatName, setRetreatName] = useState([]);
  const [footerSectionData, setFooterSectionData] = useState([]);
  const [travelcategoryArray, setTravelCategory] = useState([]);
  const handleNewLetter = async () => {
    const addNewsLetterPayload = {
      name: name,
      email: email,
    };
    const response = await addNewsLetter(addNewsLetterPayload);

    if (response.remote === "success") {
      swal("Newsletter Successfully Added!");
      window.location.reload(false);
      setName("");
      setEmail("");
    } else {
    }
  };
  const handleFooterSection = async () => {
    const response = await footerSection();

    if (response.remote === "success") {
      setFooterSectionData(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    setName("");
    setEmail("");
  }, []);
  useEffect(() => {
    handleFooterSection();
  }, []);


  // open link
  const handleOpenLink = (link) => {
    let result = link.split('/')[0]
    if(result === 'https:' || result === 'http:') {

      window.open(link, '_blank')
    } else {
      window.open(`https://${link}`, '_blank')
    }
  }

  const handleRestaurantName = async () => {

    
    const response = await restaurantNameList();

    if (response.remote === "success") {
      setRestaurantName(response.data.data);
    } else {
    }
  };
  const [storytitle, setStoryTitle] = useState([]);

  const handleStoryTitle = async () => {

    
    const response = await showStoryTitle();

    if (response.remote === "success") {
      setStoryTitle(response.data.data);
    } else {
    }
  };
  const handleRetreatName = async () => {

    
    const response = await RetreatCategorySection();

    if (response.remote === "success") {
      setRetreatName(response.data.data);
    } else {
    }
  };
  const handlTravelCategory = async () => {
    const response = await TravelCategorySection();

    if (response.remote === "success") {
      setTravelCategory(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleRestaurantName()
    handleRetreatName()
    handlTravelCategory()
    handleStoryTitle()
  }, [])
  const categoryList = [];
  travelcategoryArray?.map((item, index) => {
    categoryList.push({
      id: item.id,
      title: item.name,
      subUrl: `/travels-tours?catagoryId=${item.id}`,
    });
  });
  categoryList.push(
    { id: "2", title: "Cruises", subUrl: "/samadhi-cruises" },
    // { id: "5", subLink: "Fact about Malaysia", subUrl: "#" }
  );


  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={{ xs: 2 }}
          justifyContent="center"
          className="footer-prev"
          alignItems="center"
        >
          <Grid item xs={24} md={9}>
            <Form>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item md={3} xl={3} xs={12}>
                  <h3 className="font-45 m-0">Newsletter</h3>
                </Grid>
                <Grid item md={2} xs={3}>
                  <input
                    className="form-control footer_input_one h-64"
                    placeholder="Name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item md={5} xl={5} xs={6}>
                  <input
                    className="form-control footer_input_two h-64"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item md={2} xs={3}>
                  <Button
                    className="h-64 footer_btn_submit"
                    onClick={handleNewLetter}
                  >
                    Sign up
                  </Button>
                </Grid>
              </Grid>{" "}
            </Form>
          </Grid>
        </Grid>
        <Grid container spacing={{ md: 5, xs: 2 }} className="footer-main">
          <Grid item xs={24} lg={3} className="first_section_link">
            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
              More Information
            </Typography>
            <Grid className="footer-list">
              {" "}
              <List>
                {moreInformation.map((item, index) => (
                  <ListItem key={index}>
                    <a href={item.url}>{item.title}</a>
                  </ListItem>
                ))}{" "}
                <br></br>
                <ListItem>
                  <a href="/contact-us">Contact us</a>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid item xs={24} lg={3} className="second_section_link">
            <Grid className="footer-cont">
              <Typography
                sx={{ mt: 4 }}
                className=" mb-2"
                variant="h6"
                component="div"
              >
                Retreats
              </Typography>
              {retreatName?.map((item, i) => (
                <span key={i}>
                  <a
                    href={`/${item.slug}?catagoryId=${item.id}`}
                    onClick={() => scrollToTop()}
                  >
                    {item.title}, {item.other}
                  </a>
                  <br></br>
                </span>
              ))}
            </Grid>
            <Grid className="footer-cont">
              <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                Restaurants
              </Typography>
              {restaurantName.map((item, i) => (
                <span key={i}>
                  <a
                    href={`/${item.slug}/${item.id}`}
                    onClick={() => scrollToTop()}
                  >
                    {item.title}, {item.restaurant}
                  </a>
                  <br></br>
                </span>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={24} lg={3} className="third_section_link">
            <Typography
              sx={{ mt: 4, mb: 2 }}
              variant="h6"
              component="div"
              className="first_head_foot"
            >
              Travels
            </Typography>
            <Grid className="footer-list">
              {" "}
              <List>
                {categoryList.map((item, i) => (
                  <ListItem key={i}>
                    <Link to={item.subUrl} onClick={() => scrollToTop()}>
                      {item.title}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Typography
              sx={{ mt: 4, mb: 2 }}
              variant="h6"
              component="div"
              className="second_head_foot"
            >
              Stories
            </Typography>
            <Grid className="footer-list">
              <List>
                {storytitle.map((item, i) => (
                  <ListItem key={i}>
                    <Link
                      to="/samadhi-experience"
                      onClick={() => scrollToTop()}
                    >
                      {item.title}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
          <Grid item xs={24} lg={3}></Grid>
        </Grid>
        <Grid
          container
          className="footer-sub"
          style={{ marginBottom: location.pathname === "/stay" ? "85px" : "" }}
        >
          <Grid item xs={24} lg={3}>
            {" "}
            <a href="/">
              <img src={FooterNew} alt="" className="footer-logo-check" loading="lazy" width={125} height={55}   priority={false} />
            </a>
          </Grid>
          <Grid item xs={24} lg={6}>
            <div className="social-link-footer">
              <p className="m-0"> Follow Us:</p>{" "}
              <ul className="m-0">
                <li>
                  <Button
                    onClick={() => handleOpenLink(footerSectionData[0]?.value)}
                  >
                    {instagram}
                  </Button>
                </li>

                <li>
                  <Button
                    onClick={() => handleOpenLink(footerSectionData[1]?.value)}
                  >
                    {faceBook}
                  </Button>
                </li>
                <li>
                  {/* <a href=>{youtube}</a> */}
                  <Button
                    onClick={() => handleOpenLink(footerSectionData[2]?.value)}
                  >
                    {youtube}
                  </Button>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} lg={3} className="text-end copyright_div">
            <span>
              Copywrite {footerSectionData[3]?.value}, Samadhi Retreats
            </span>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
