exports.Base_URL = "https://3rdeye.samadhiretreats.com/api/";
// exports.Base_URL = "http://192.168.0.105:8000/samadhidev/public/api/";
exports.Base_URL = `${process.env.REACT_APP_SERVER_URL}/api/`;

exports.CV_URL = `${process.env.REACT_APP_SERVER_URL}/upload/profile/`;
exports.Featured_URL =
  `${process.env.REACT_APP_SERVER_URL}/upload/gift/`;
exports.Offers_URL =
  `${process.env.REACT_APP_SERVER_URL}/upload/offers/`;
exports.Ticket_URL =
  `${process.env.REACT_APP_SERVER_URL}/upload/ticket/`;
exports.Celebration_URL =
  `${process.env.REACT_APP_SERVER_URL}/upload/images/`;
