import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
function Content({
  type,
  title,
  shortDescription,
  id,
  packageDescription,
  package_feature,
  package_duration,
  offerType,
  btnName,
  terms,
  enquiry_link,
  other,
  ticketData,
  ticketSlug,
  booking,
}) {
  console.log(booking, "--->booking");
  const navigate = useNavigate();
  return (
    <div>
      {" "}
      <Grid className="cards heading exclusive-card-data">
        <h4>{title !== "null" ? title : ""}</h4>
        <p className="mb-4">
          {shortDescription !== "null" ? shortDescription : ""}
        </p>
        <h4 style={{ color: "#fff" }} className="mb-4">
          {package_duration !== "null" ? package_duration : ""}{" "}
        </h4>
        <span>
          {" "}
          <div dangerouslySetInnerHTML={{ __html: packageDescription }} />
        </span>
        <div className="exlusive_enur_btn">
          {ticketData !== 0 && offerType === "restaurants" ? (
            <>
              <Button
                onClick={() => navigate(`/samadhi/${ticketSlug}/${id}`)}
                className="dashed-btn mt-5" style={{whiteSpace:"nowrap"}}
              >
                Book With Us
              </Button>
            </>
          ) :  offerType === "retreats" ? (
            <>
              <Button
                onClick={() => {
                  const element = document.createElement("a");
                  element.href = enquiry_link;
                  element.target="_blank"
                  element.click();
                  // navigate(booking)
                }}
                className="dashed-btn mt-5"
                style={{whiteSpace:"nowrap"}}
              >
                Book With Us
              </Button>
            </>
          ) : (
            ""
          )}
        </div>
        <p className="mt-5 mb-0">Terms and conditions</p>
        <p>{terms !== "null" ? terms : ""}</p>
        <div className="d-flex">
          <input className="white-input-small mt-5 me-3" placeholder="1" />
        </div>
      </Grid>
    </div>
  );
}

Content.propTypes = {
  // type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  descriptionTitle: PropTypes.string,
  feature_title: PropTypes.string,
  package_duration: PropTypes.string,
  package_feature: PropTypes.array,
};

export default Content;
