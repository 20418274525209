import axios from "axios";
import { parse, stringify } from "qs";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/`,
  headers: {
    "Content-Type": "application/json",
  },
});

// @axios interceptors

// @response interceptor

// @request function for api call.

export const request = async (config) => {
  try {
    if (!config.headers) {
      config.headers = {};
    }
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }
    const token = localStorage.getItem("token");
    config.headers["Authorization"] = `Bearer ${token}`;
   
    const response = await axiosInstance.request({ ...config });

    return {
      remote: "success",
      data: response.data,
    };
  } catch (error) {
    if (error) {
      if (error.response) {
        if (error.response && error.response.data) {
          let errorMessage = error.response.data.erros;

          if (error.response.status === 500) {
            errorMessage = "Internal Server Error!";
          }

          return {
            remote: "failure",
            error: {
              status: error.response.status,
              errors: errorMessage,
            },
          };
        }
      } else {
        let errorMessage = error.message;

        return {
          remote: "failure",
          error: {
            errors: errorMessage,
          },
        };
      }
    }
    throw error;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { axiosInstance, request };
