import {React,useEffect} from 'react'
import { Grid } from '@mui/material'
import { Container } from '@mui/material'
import Slider from 'react-slick'
import IMAGES from '../../../assets/images'
import SubHeader from '../../../components/layout/SubHeader'
import BannerSlider from '../../../components/BannerSlider'
import OfferSlider from '../../../components/OfferSlider'
import TestimonialSlider from '../../../components/TestimonalSlider'
import TabButton from '../TabButton'
import EventCard from '../EventCard'
import { Link, useNavigate } from "react-router-dom";
import { showfeaturedstory } from '../../../api/frontend'
import { useState } from 'react'
import { Featured_URL } from "../../../serverUrl";
import { Helmet } from 'react-helmet'
const {
  celebrateOne,
  stayBanner,
  vsklBanner,
  experience7,
  experience6,
  EventTwo,
  EventThree,
  EventFour,
  flower,
} = IMAGES;

export default function TamarindHillSingaporeTwo() {
  const navigate = useNavigate();
  const BannerArray = [flower, celebrateOne, stayBanner, vsklBanner];

  const [experienceArray, SetexperienceArray] = useState([]);
  const eventArray = [
    {
      image: EventThree,
      title: "Weddings",
      description:
        "Timeless Romance. Nestled on the fringe of Labrador Nature Reserve, encircled by lush, tropical greenery - two colonial properties provide the perfect setting for couples seeking a wedding with a difference. To ensure your day goes perfectly, a dedicated team of experienced wedding specialists will also be there for you. From invitations to wedding cakes, from floral creations to photography arrangements, they can help plan and create a truly memorable, picture-perfect event.",
      id: "1",
      linkUrl: "/celebration-and-events-tamarind-hill-singapore-enquiry ",
    },
    {
      image: EventFour,
      title: "Events",
      description:
        "Housed in a 1920s heritage colonial bungalow within Labrador Nature Reserve, Tamarind Hill offers a distinctive event setting. Although set in a century-old colonial bungalow, Tamarind Hill offers the technical facilities required of any modern corporate occasion. Whether sales presentation, product launch or company dinner we’re equipped to make sure your event runs smoothly. Experienced dedicated planners and an award-winning culinary team, work tirelessly to ensure that your event will be a success. More casual corporate gatherings can take advantage of our grounds which, located on the fringes of the jungle, provide an abundance of greenery and tropical chatter. It’s an outdoor venue like no other. A customised African stretch tent that can accommodate up to 100 seated guests is also available. Needless to say, whether indoors our outdoors, delicious award-winning Thai cuisine, friendly service and fastidious planning will combine to make your special event nothing short of memorable.",
      id: "2",
      linkUrl: "/celebration-and-events-tamarind-hill-singapore-enquiry",
    },
  ];
  var experienceSlide = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
  };
  var exclusiveSlider = {
    arrow: true,
    dots: false,
    infinite:true,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const handlefeaturedstory = async () => {
    const response = await showfeaturedstory();

    if (response.remote === "success") {
      SetexperienceArray(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handlefeaturedstory();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Weddings & Events | Venues Kuala Lumpur | Samadhi Retreats
        </title>
        <meta
          name="description"
          content="From private executive meetings to breathtaking jungle-enclave weddings, discover versatile event spaces  that's perfect for you."
        />
      </Helmet>
      <div className="tamarind-celebration celebrate-singapore-page">
        <div className=" main-bg">
          <Container maxWidth="xl">
            <div className="sub-header-div mb-4">
              <SubHeader
                pageTitle="Celebrations & Events"
                subHeading={[
                  {
                    id: "0",
                    subLink: "Malaysia",
                    subUrl: "/celebration-event-",
                  },
                  {
                    id: "1",
                    subLink: "Singapore",
                    subUrl: "/weddings-events-singapore",
                  },
                ]}
              />
            </div>
          </Container>
          <Container maxWidth="xl" className="banner_container">
            <div className="banner-div">
              <h2
                className="text-center"
                // data-aos="fade-up"
                // data-aos-duration="1500"
                // data-aos-once="true"
              >
                Celebrations at Tamarind Springs
              </h2>
              <div data-aos="fade-up" data-aos-duration="3000">
                <BannerSlider BannerArray={BannerArray} />
              </div>
            </div>
          </Container>

          <div className="celebration_cards_main ">
            <Container maxWidth="xl">
              <div className="mt-4">
                <TabButton />
              </div>
              <div className=" pt-20  event-card-list ">
                {eventArray.map((item, index) => {
                  return (
                    <div
                      className="event-card"
                      onClick={() =>
                        navigate("/celebration-event-tamarind-singapore")
                      }
                    >
                      {" "}
                      <EventCard {...item} key={index} />
                    </div>
                  );
                })}
              </div>
            </Container>
          </div>

          <div className="experience-div-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3>Stories</h3>
              </Grid>
              <Slider
                {...experienceSlide}
                className="experience-slider celebrate-customer-div"
              >
                {experienceArray.map((item, index) => (
                  <Grid className="testimonial-type-card">
                    <Grid
                      container
                      spacing={{ md: 6, xs: 2 }}
                      className="align-items-center d-flex "
                      data-aos="fade-up"
                      data-aos-once="true"
                      data-aos-duration="10000"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={4}>
                        <div>
                          {" "}
                          <Grid className="cards heading">
                            <h3>{item.discription}</h3>
                            <h3
                              className="mt-5"
                              style={{ fontStyle: "normal" }}
                            >
                              {item.heading}
                            </h3>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <img src={`${Featured_URL}${item.image}`} alt="" />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Slider>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
