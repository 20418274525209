import React from "react";
import IMAGES from "../../../assets/images";
function Image({ image, trastion, delay }) {
  return (
    <>
      <img src={image} alt="" data-aos={trastion} data-aos-duration={delay} />
    </>
  );
}

export default Image;
