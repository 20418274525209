import { React, useEffect, useState } from "react";
import SubHeader from "../../components/layout/SubHeader";
import { Grid, MenuItem } from "@mui/material";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import TestimonialSlider from "../../components/TestimonalSlider";
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  exclusiveoffers,
  exclusiveoffersCategory,
  setTotalList,
} from "../../api/frontend";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { subMenuArrow } from "../../utils/svg.file";
import { Button } from "react-bootstrap";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";


export default function ExclusiveHomePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [retreatName, setRetreatName] = useState([]);
  const handleRetreatName = async () => {
    const response = await setTotalList();

    if (response.remote === "success") {
      setRetreatName(response.data.data);
    } else {
    }
  };
  useEffect(() => {
    handleRetreatName();
  }, []);
  const [countrytype, setCountrytype] = useState("");
  const [categorydata, setCategorydata] = useState([]);
  const [isLoadingData, setIslodingData] = useState(true);
  const searchData = searchParams.get("state");

  // const handleexclusive = async () => {
  //   const response = await exclusiveoffers();

  //   if (responseNaNpxote === "success") {
  //     setTimeout(() => {
  //   setIslodingData(false);
  // }, 3000);
  //     setTravelArray(response.data.travels);
  //     setretreats(response.data.retreats);
  //     setdining(response.data.dining);
  //     setcelebration(response.data.celebration);
  //   } else {
  //   }
  // };
  const handleexclusiveCategory = async () => {
    setIslodingData(true);

    const response = await exclusiveoffersCategory();

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      console.log(response.data.data,'oiii')
      setCategorydata(response.data.data);
    } else {
    }
  };

  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 15,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 15,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 15,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };
  // const onChange = ((type)=>{
  //   handleexclusiveCategory()
  //   setCountrytype(type);
  // });
  const [age, setAge] = useState("");
  const [restaurantFilter, setRestaurantFilter] = useState("");


  // data return
  const exclusiveSectionData = (exclusiveData, filterRetreat, filterRestaurent) => {
    // console.log({ exclusiveData, filterData });
    if (filterRetreat) {
      return exclusiveData.filter((el) => el.locationId === filterRetreat &&   el.offerType === 'retreats');
    } else if(filterRestaurent){
      return exclusiveData.filter((el) => el.locationId === filterRestaurent &&   el.offerType === 'restaurants');
    } else {
      return exclusiveData;
    }
  };

  const handleChange = (e) => {
    setAge(e.target.value);
    setRestaurantFilter("")
  };
  useEffect(() => {
    // handleexclusive();
    handleexclusiveCategory();
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Offers | Hotels & Restaurants | Samadhi Retreats</title>
        <meta
          name="description"
          content="Get the best rates and offers available when you book directly from our website. Find out more."
        />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}
      <div className="exclusive-offer-page">
        <div className="room-type-page main-bg">
          <Container maxWidth="xl">
            <div className="sub-header-div">
              <div className="sub-header">
                <Row className="g-0">
                  <Col
                    lg={12}
                    className="d-flex justify-content-md-center  justify-content-sm-center sub-header-title"
                  >
                    <h3>
                      Offers
                      {/* <span className="ms-2">{subMenuArrow}</span> */}
                    </h3>
                  </Col>
                  {/* <Col lg={6}>
                    <ul className="ps-0 pt-20 ">
                      <li>
                        <Button
                          onClick={() => onChange("malaysia")}
                          className={countrytype === "malaysia" ? "active" : ""}
                        >
                          Malaysia
                        </Button>
                      </li>
                      <li>
                        <Button
                          onClick={() => onChange("singapore")}
                          className={
                            countrytype === "singapore" ? "active" : ""
                          }
                        >
                          Singapore
                        </Button>
                      </li>
                    </ul>
                  </Col> */}
                </Row>
              </div>
            </div>

            {/* restaurant and retreat filter start  */}
            {/* <div className="text-end">
              {" "}
              <FormControl className="text-start mt-3" sx={{ minWidth: 120 }}>
                <select
                  style={{ height: "35px" }}
                  placeholder="Available venue"
                  className="enquiry-input"
                  onChange={handleChange}
                  value={age}
                >
                  <option value="" disabled>
                    Select Retreats
                  </option>
                  {retreatName?.map((item, index) => {
                    return (
                      item.status === "retreat" && (
                        <>
                          <option value={item.value}>
                            {item.label}, {item.other}
                          </option>
                        </>
                      )
                    );
                  })}
                </select>
              </FormControl>
              {" "}
              <FormControl className="text-start mt-3" sx={{ minWidth: 120 }}>
                <select
                  style={{ height: "35px" }}
                  placeholder="Available venue"
                  className="enquiry-input"
                  onChange={e => {setRestaurantFilter(e.target.value);
                  setAge("")}}
                  value={restaurantFilter}
                >
                  <option value="" disabled>
                    Select Restaurants
                  </option>
                  {retreatName?.map((item, index) => {
                    return (
                      item.status === "restaurant" && (
                        <>
                          <option value={item.value}>
                            {item.label}, {item.other}
                          </option>
                        </>
                      )
                    );
                  })}
                </select>
              </FormControl>
            </div> */}
            {/* restaurant and retreat filter end  */}
          </Container>
          {categorydata?.map((item) => {
            let exclusiveSection = item.exclusive_section;
            if (countrytype) {
            
              exclusiveSection = item.exclusive_section.filter(
                (el) => el.country === countrytype
              );
            }
            return (
              <div className="stay-section">
                <Container maxWidth="xl">
                  <div className="normal-card-section">
                    <Grid className="text-center section-head">
                      <h3 className="geting-text">{item.title}</h3>
                    </Grid>

                    <div className="btn-slider-div">
                      <Carousel
                        autoPlaySpeed={1200}
                        arrows={true}
                        partialVisbile={
                          exclusiveSectionData(exclusiveSection, age, restaurantFilter).length < 4
                            ? false
                            : true
                        }
                        // sliderClass="centerslider"
                        responsive={responsive}
                        autoPlay={false}
                        infinite={false}
                        // className="mt-100"
                      >
                        {exclusiveSectionData(exclusiveSection, age, restaurantFilter)?.map(
                          (content, index) => {
                            var gotIndex = index % 2 == 0;

                            return (
                              <>
                                {gotIndex ? (
                                  <div
                                    onClick={() =>
                                      navigate(`/offers/${content.other}`)
                                    }
                                    className="normal-card-div cursor-btn"
                                    key={content.id}
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                  >
                                    <OfferSlider {...content} />
                                  </div>
                                ) : (
                                  <div
                                    onClick={() =>
                                      navigate(`/offers/${content.other}`)
                                    }
                                    className="normal-card-div  cursor-btn"
                                    key={content.id}
                                    data-aos="fade-down"
                                    data-aos-duration="1000"
                                  >
                                    <OfferSlider {...content} />
                                  </div>
                                )}
                              </>
                            );
                          }
                        )}
                      </Carousel>
                    </div>
                  </div>
                </Container>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
