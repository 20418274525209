import React from "react";
import PropTypes from "prop-types";
import Content from "./content";
import Image from "./image";
import { Grid } from "@mui/material";

function  OfferSlider(props) {
  return (
    <div>
      <Grid className="card-design">
        <Image {...props} />
        <Content {...props} />
      </Grid>
    </div>
  );
}

OfferSlider.propTypes = {
  image: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  descriptionTitle: PropTypes.string,
};

export default OfferSlider;
