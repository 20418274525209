import React from "react";
import { envelope } from "../../utils/svg.file";
import { useNavigate } from "react-router-dom";

export default function ContactCard(props) {
  const navigate=useNavigate()
  return (
    <>
      <div className="contact-cards w-100  ">
        <p className="address-city">{props.item.location}</p>
        <div className="d-flex justify-content-between w-100">
          <ul className="address-detail">
            <li>{props.item.pagename}</li>
            <li>{props.item.address}</li>
            <li>
              {" "}
              {props.item.firstmobile !== "null" && props.item.firstmobile ? (
                <>
                  <span
                    className="cursor-btn"
                    onClick={() => {
                      if(props.item.f_status === "true"){

                      window.location = `https://api.whatsapp.com/send?text=${props.item.firstmobile}`;
                      }
                    }}
                  >
                    T +{" "}
                    {props.item.firstmobile !== "null"
                      ? props.item.firstmobile
                      : ""}{" "}
                    {props.item.f_status === "true" ? "(WhatsApp Enabled)" : ""}
                  </span>
                </>
              ) : (
                ""
              )}
              <br></br>
              {props.item.secondmobile !== "null" && props.item.secondmobile ? (
                <>
                  <span
                    className="cursor-btn"
                    onClick={() => {
                      if(props.item.f_status === "true"){

                      window.location = `https://api.whatsapp.com/send?text=${props.item.secondmobile}`;
                      }
                    }}
                  >
                    +{" "}
                    {props.item.secondmobile !== "null"
                      ? props.item.secondmobile
                      : ""}{" "}
                    {props.item.s_status === "true" ? "(WhatsApp Enabled)" : ""}
                  </span>
                </>
              ) : (
                ""
              )}
            </li>
            {props.item.thirdmobile !== "null" && props.item.thirdmobile ? (
              <>
                {" "}
                <li
                  className="cursor-btn"
                  onClick={() => {
                      if(props.item.f_status === "true"){

                      window.location = `https://api.whatsapp.com/send?text=${props.item.thirdmobile}`;
                      }
                    }}
                >
                  {" "}
                  +{" "}
                  {props.item.thirdmobile !== "null"
                    ? props.item.thirdmobile
                    : ""}{" "}
                  {props.item.t_status === "true" ? "(WhatsApp Enabled)" : ""}
                </li>{" "}
              </>
            ) : (
              ""
            )}
          </ul>
          <div className="address-icon">
            {props.item.other && props.item.other !== "null" ? (
              <span
                onClick={() => (window.location = `mailto:${props.item.other}`)}
              >
                {envelope}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}
