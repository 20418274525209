import { React, useEffect } from "react";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import OfferSlider from "../../components/OfferSlider";
import ExclusiveCard from "./ExclusiveCard";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ExclusiveBlock, exclusiveoffers } from "../../api/frontend";
import { useState } from "react";
import { Helmet } from "react-helmet";

export default function VSKLExclusiveOfferOne() {
  const [BlockCelebration, setCelebrationBlock] = useState([]);
  const [retreatData, setRetreatData] = useState("");
  const [restaurentList, setRestaurentList] = useState([]);

  const [searchParams] = useSearchParams();
  const handleExclusiveBlock = async (id, type) => {
    const response = await ExclusiveBlock(id, type);

    if (response.remote === "success") {
      setTimeout(() => {}, 3000);
      console.log(response.data, "rrrevsg");
      setRetreatData(response.data.retreat);
      setCelebrationBlock(response.data.data);
    } else {
    }
  };
  // let rows = [];

  // BlockCelebration?.map((item, index) => {
  //   rows.push({
  //     bannerImage: item.image,
  //     subcategory: "Exculsive Offers",
  //     title: item.title,
  //     shortDescription: item.shortDescription,
  //     id: item.id,
  //     other: item.other,
  //     speed: "fade-up",
  //     dataspeed: "1500",
  //   });
  // });
  const handleexclusive = async () => {
    const response = await exclusiveoffers();

    if (response.remote === "success") {
      setTimeout(() => {}, 3000);
      setRestaurentList(response.data.data);
    } else {
    }
  };
  const [scriptItem, setScriptItem] = useState('');
  useEffect(() => {
    const catagoryId = searchParams.get("retreatId");
    const typeData = searchParams.get("type");

    handleExclusiveBlock(catagoryId, typeData);
    handleexclusive();
    setScriptItem(catagoryId === '17' 
      ? `
          (function () {
          var params = {
              hpr: "JapaMala Resorts",
              hpid: "65c451e2-21c7-454a-8fab-d3dd692d78e8",
              sha256_eml: "",
              sha1_eml: "",
              md5_eml: "",
              ccid: "",
              pt: ""
          };
          params.et = {"HOME_PAGE":null,"SEARCH":"hs","PRODUCT":"hpr","SHOPPING_CART":"hcart","CONVERSION":"hc","TRACKING":null}[params.pt] || '';
          try { params = Object.assign({}, sjrn_params, params); } catch(e) {}
          var paramsArr = [];
          for (key in params) { paramsArr.push(key + '=' + encodeURIComponent(params[key])); }
          var pl = document.createElement('iframe');
          pl.type = 'text/html';
          pl.setAttribute('style', 'height:0; width:0; display:none;');
          pl.async = true;
          pl.src = "https://static.sojern.com/cip/c/206.html?f_v=cp_v3_js&p_v=1&" + paramsArr.join('&');
          (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(pl);
          })();`
      : `window.sjn=window.sjn||function(){(sjn.q=sjn.q||[]).push(arguments)};
         sjn('initAndFire', 'advertiser', '15207', 'tracking', { 
             context: { vertical: 'hotel' },
             params: {}
         });`
  );
  }, [searchParams]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
    },
  };

  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />

        <script src="https://static.sojern.com/sdk/latest/sojern.min.js"></script>
          <script src='https://static.sojern.com/utils/sjrn_autocx.js'></script>
          <script type="text/javascript">{scriptItem}</script>

      </Helmet>
      <div className="travel-category vskl-ex-offer vskl-taste">
        <div className=" main-bg">
          {/* <Container maxWidth="xl">
            <div className="sub-header-div">
              <SubHeader
                pageTitle="Offers"
                subHeading={[
                  {
                    id: "0",
                    subLink: "Malaysia",
                    subUrl: "#",
                  },
                  {
                    id: "1",
                    subLink: "Singapore",
                    subUrl: "#",
                  },
                ]}
              />
            </div>
          </Container> */}

          <div className="">
            <Container maxWidth="xl">
              <div className="exclusive-newnew pb-80">
                <Grid className="text-center section-head">
                  <h3 className="geting-text">
                    Offers -{retreatData.title} {retreatData.other}
                  </h3>
                </Grid>
                {BlockCelebration.map((item, index) => (
                  <div
                    key={index}
                    data-aos-once="true"
                    data-aos={item.dataspeed}
                    data-aos-duration={item.speed}
                    className="bottom-cards-div  mt-100"
                  >
                    <ExclusiveCard
                      {...item}
                      booking={retreatData.booking}
                      ticketData={item.ticketData.length}
                      ticketSlug={item.ticketData[0]?.other}
                    />
                  </div>
                ))}
              </div>
            </Container>
          </div>
          <div className=" other-div">
            <Container maxWidth="xl">
              <div className="btn-slider-div">
                <Carousel
                  autoPlaySpeed={1200}
                  arrows={true}
                  ssr={true}
                  partialVisbile={restaurentList.length < 4 ? false : true}
                  // sliderClass="centerslider"
                  responsive={responsive}
                  autoPlay={false}
                  infinite={false}
                  className="mt-100"
                >
                  {restaurentList.slice(0, 5).map((item, index) => (
                    <div
                      key={index}
                      className="bottom-cards-div"
                      data-aos={item.dataspeed}
                      data-aos-once="true"
                      data-aos-duration={item.speed}
                    >
                      <OfferSlider {...item} />
                    </div>
                  ))}
                </Carousel>
                <div className="text-center">
                  {" "}
                  <Link to="/offers" className="all-show-btn text-center">
                    View all Offers
                  </Link>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
