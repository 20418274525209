import { Grid } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
const ExclusiveBook = (props) => {
  const navigate = useNavigate();
  return (
    <div className="exclusiveboox" style={{ background: props.bgcolor }}>
      <Grid container spacing={5} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={3} className="text-center">
          <h3 style={{ color: props.heddingcolor }}>Offers</h3>
        </Grid>
        <Grid item md={2} xs={12} className="text-center">
          <button
            onClick={() =>
              navigate("/exclusive-offer-villa-samadhi-kualalumpur")
            }
            style={{
              background: props.color,
              borderColor: props.bordercolor,
              color: props.textcolor,
            }}
          >
            Book Now
          </button>
        </Grid>
      </Grid>
    </div>
  );
};
export default ExclusiveBook;
