import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import ContactCard from "../../components/contactCard";
import IMAGES from "../../assets/images";
import { showContact } from "../../api/userPanel/contactPage";
import { Helmet } from "react-helmet";
const { contactOne, contactTwo } = IMAGES;
export default function Contactus() {
  const [contactData, setContactData] = useState([]);
  const handleShowContactDetail = async () => {
    const response = await showContact();

    if (response.remote === "success") {

      // setContactData(response.data.data);
      let tempArr = response.data.data;
      setContactData(tempArr.sort((a, b) => a.order - b.order));
    } else {
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    handleShowContactDetail();
  }, []);
  return (
    <>
      <Helmet>
<meta charSet="utf-8" />
<title>Find Luxury Honeymoon Resorts I Honeymoon Hotels in Kuala Lumpur</title>
<meta name="description" content=' Samadhi Retreats - Explore Luxury Honeymoon Resorts & Hotels in Kuala Lumpur. Contact us for personalized unforgettable memories on your special getaway!'/>
<meta name="keywords" content='find luxury honeymoon resorts, honeymoon hotels in kuala lumpur, long stay package kuala lumpur, long stay package malaysia, luxury villa malaysia, nature stay malaysia, nature staycation malaysia, pool hotel room kuala lumpur, pool hotel room malaysia, pool villa malaysia, private pool malaysia, private villa malaysia, quality hotel kuala lumpur, recommended hotel kuala lumpur, residence kuala lumpur, resort kuala lumpur, retreat kuala lumpur, retreat malaysia, retreat resort malaysia, romantic boutique villa malaysia' />
<link rel="canonical" href="https://www.samadhiretreats.com/contact-us"/>
</Helmet>
      <div className="contact-us-page">
        <div className=" main-bg">
          <div className="banner-heading">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3 className="geting-text">Contact Us</h3>
              </Grid>
            </Container>
          </div>
          <div className="data-list-div">
            <Container maxWidth="xl">
              <div className="address-div-pic">
                <Grid spacing={12} container>
                  <Grid item md={3}>
                    <div className="address-list">
                      <h4 className="address-title">Central Reservation</h4>
                      {contactData.map((item, index) => {
                        if (Number(item.category) === 1) {
                          return <ContactCard item={item} key={index} />;
                        }
                      })}
                    </div>
                  </Grid>
                  <Grid md={1}></Grid>
                  <Grid item md={7}>
                    <div className="address-div-right">
                      <img src={contactOne} alt="" />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="address-div-pic">
                <Grid spacing={8} container>
                  <Grid item md={4}>
                    <div className="address-div-right">
                      <img src={contactTwo} alt="" />
                    </div>
                  </Grid>{" "}
                  <Grid item md={3}>
                    <div className="address-list">
                      <h4 className="address-title">Hotel & Resorts</h4>
                      {contactData.map((item, index) => {
                        if (Number(item.category) === 2) {
                          return <ContactCard item={item} key={index} />;
                        }
                      })}
                    </div>
                  </Grid>
                  <Grid md={1}></Grid>
                  <Grid item md={3}>
                    <div className="address-list tamarind-resort">
                      <h4 className="address-title">Tamarind Restaurants</h4>
                      {contactData.map((item, index) => {
                        if (Number(item.category) === 3) {
                          return <ContactCard item={item} key={index} />;
                        }
                      })}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
