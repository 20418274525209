import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import IMAGES from "../../assets/images";
import Carousel from "react-multi-carousel";
import { CloseCircle, rightArrow } from "../../utils/svg.file";
import { Offers_URL } from "../../serverUrl";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import Slider from "react-slick";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "#403836",
  boxShadow: 24,
  p: 0,
};
const { vsklonenewgallery } = IMAGES;
const GalleryModal = forwardRef((props, ref) => {
  const [sliderIndex, setSliderIndex] = useState(1);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      // partialVisibilityGutter: 20,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      // paritialVisibilityGutter: 20,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      // partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
      
    },
  };
  const galleryArray = [
    {
      img: vsklonenewgallery,
    },
    {
      img: vsklonenewgallery,
    },
  ];

  const sliderReff = React.useRef();
  const [gallery, setGallery] = React.useState([]);
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(1);
  var bannerSetting = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    afterChange: (indexOfCurrentSlide) => {
      setSliderIndex(indexOfCurrentSlide + 1);
    },
  };

  const handlechangeCurrentslide = (index) => {
    sliderReff.current.slickGoTo(index);
  };
  useImperativeHandle(ref, () => ({
    handleChangeslide: handlechangeCurrentslide,
  }));
  return (
    <div>
      {/* <Button onClick={props.handleGalleryModalOpen}>Open modal</Button> */}
      <Modal
        open={props.imagesOpen}
        onClose={props.handleGalleryModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="vskl-gallery-modal gallery-one">
          <span
            className="close-slider cursor-btn"
            onClick={props.handleGalleryModalClose}
          >
            {CloseCircle}
          </span>
          <div className="gallery-slider-height-div">
            <div className="gallery-div-massonary-min ">
              <Row className="justify-content-center  btn-slider-div">
                <Col md={10}>
                  {" "}
                  {/* <Carousel
                    ref={sliderReff}
                    slidesToShow={1}
                    slidesToScroll={1}
                    centerMode={false}
                    arrows={true}
                    ssr={true}
                    itemClass="carousel-item-padding-40-px"
                    partialVisible={true}
                    autoPlaySpeed={20000}
                    responsive={responsive}
                    autoPlay={false}
               infinite={true}
                    containerClass="carousel-container"
                    customRightArrow={<CustomRightArrow />}
                  > */}{" "}
                  <Slider
                    ref={sliderReff}
                    {...bannerSetting}
                    initialSlide={props.clicked || 0}
                  >
                    {props.imagesGallery
                      .slice(0)
                      .reverse()
                      .map((item, index) => (
                        <Box className="" key={index}>
                          <img
                            src={`${Offers_URL}${item}`}
                            alt=""
                            className="mx-auto"
                          />
                        </Box>
                      ))}
                  </Slider>
                  {/* </Carousel> */}
                </Col>
              </Row>

              {/* <div className="slider-count-text text-center">
                <span>{currentSlideIndex}</span> /{" "}
                <span>{props.imagesGallery.length}</span>
              </div> */}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
});

export default GalleryModal;
