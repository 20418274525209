import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Content from "./content";
import { cross } from "../../utils/svg.file";

export default function JobModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "1px solid #707070",
    p: 4,
  };
  return (
    <>
      <div className="job-modal">
        <Modal
          open={props.jobDetailModal}
          onClose={props.handleJobDetailHide}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex justify-content-end">
              <span
                className="close-job-detail mb-3"
                onClick={props.handleJobDetailHide}
              >
                {cross}
              </span>
            </div>
            <Content {...props} />
          </Box>
        </Modal>
      </div>
    </>
  );
}
