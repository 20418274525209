import axiosInstance from "./../api";

export const showContact = async () => {
  const response = await axiosInstance.request({
    url: "show_contact",
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  return response;
};
