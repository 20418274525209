import React from "react";
import { Offers_URL } from "../../../serverUrl";
function Image({ bannerImage, trastion, delay }) {
  return (
    <>
      <img src={`${Offers_URL}${bannerImage}`} alt="" data-aos={trastion} data-aos-duration={delay} />
    </>
  );
}

export default Image;
