import { Container } from "@mui/system";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DatePicker from "react-multi-date-picker";
export default function ReservationTab() {
  const [arrivalDate, setArrivalDate] = useState(new Date());
  const [departureDate, setDepartureDate] = useState(new Date());

  const [location, setLocation] = useState("");

  const handleChangeLocation = (event) => {
    setLocation(event.target.value);
  };

  return (
    <>
      {" "}
      <div className="reservation-tab-main">
        <Container maxWidth="xl">
          <div className="reservation-tab d-flex align-items-center justify-content-between">
            <h3>Reservation</h3>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <label>Location</label>
              <select value={location} onChange={handleChangeLocation}>
                <option disabled>Selected a Location</option>
                <option>Madhya Pradesh</option>
                <option>Utar Pradesh</option>
                <option>Himachal Pradesh</option>
                <option>Delhi</option>
              </select>
            </FormControl>
            <div className="date-piker-section">
              <label>Arrival</label>
              <DatePicker value={arrivalDate} onChange={setArrivalDate} />
            </div>
            <div className="date-piker-section">
              <label>Departure</label>
              <DatePicker value={departureDate} onChange={setDepartureDate} />
            </div>
            <TextField
              id="standard-basic"
              label="Guest"
              variant="standard"
              defaultValue="1"
              sx={{ width: "100px" }}
            />
            <Button className="dashed-btn">Check Availability</Button>
          </div>
        </Container>
      </div>
    </>
  );
}
