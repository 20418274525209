import React from "react";
import PropTypes from "prop-types";
import Content from "./content";
import Image from "./image";
import { Grid } from "@mui/material";

function ExclusiveCard(props) {
  return (
    <>
      <Grid className="testimonial-type-card">
        <Grid container spacing={6} data-aos="fade-up" data-aos-duration="3000">
          {" "}
          <Grid item md={8}>
            <Image {...props} />
          </Grid>
          <Grid item md={4}>
            <Content {...props} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

ExclusiveCard.propTypes = {
  image: PropTypes.string.isRequired,
  // type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default ExclusiveCard;
