import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const navigate = useNavigate();
  const handleBack = () =>{
    navigate('/')
  }
  return (
    <>
      <div class="flex-container">
        <div class="text-center">
          <h1>
            <span class="" id="digit1">
              4
            </span>
            <span class="" id="digit2">
              0
            </span>
            <span class="" id="digit3">
              4
            </span>
          </h1>
          <h3 class="">PAGE NOT FOUND</h3>
          <Button type="button" name="button" onClick={handleBack}>
            Return To Home
          </Button>
        </div>
      </div>
    </>
  );
}
