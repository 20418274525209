import { Container, Grid, Stack } from '@mui/material'
import {React,useEffect} from 'react'
import Slider from 'react-slick'
import IMAGES from '../../assets/images'

import BannerSlider from '../../components/BannerSlider'
import SubHeader from '../../components/layout/SubHeader'
import OfferSlider from '../../components/OfferSlider'
import TestimonialSlider from '../../components/TestimonalSlider'
import { Col, Row, Button } from "react-bootstrap";
import { subMenuArrow } from "../../utils/svg.file";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { addEnquiryDetail, FindCruiseenquery, showExperienceList } from '../../api/frontend'
import { useState } from 'react'
import { Offers_URL } from '../../serverUrl'
import swal from 'sweetalert'
import LoaderMain from '../../admin/component/loader'
import { Helmet } from 'react-helmet'
const {
  travelmoterbikebanner,
  experience9,
  map,
  stroy11,
  tour1,
  tour2,
  tour3,
  tour4,
  charterBanner,
  cruiseCharterMap,
} = IMAGES;
const CruiseCharter = () => {
  const [searchParams] = useSearchParams()
  const [cruiseEnquiryData,setCruiseEnquiryData] = useState([])
  const [cruiseEnquiryGallery,setCruiseEnquiryGallery] = useState([])
  const [cruiseEnquiryFaq,setCruiseEnquiryFaq] = useState([])
  const [cruiseEnquiryDay,setCruiseEnquiryDay] = useState([])
  const [firstName,setFirstName] = useState('')
  const [lastName,setLastName] = useState('')
  const [email,setEmail] = useState('')
  const [phone,setPhone] = useState('')
  const [date,setDate] = useState('')
  const [message,setMessage] = useState('')
  const [isLoadingData, setIslodingData] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handleCruisePackage = async (catagoryId) => {
    setIslodingData(true)
    const response = await FindCruiseenquery(catagoryId);

    if (response.remote === "success") {
      setTimeout(() => {
        setIslodingData(false);
      }, 3000);
      setCruiseEnquiryData(response.data.data[0])
      setCruiseEnquiryGallery(JSON.parse(response.data.data[0].gallery))
      setCruiseEnquiryFaq(JSON.parse(response.data.data[0].faq))
      setCruiseEnquiryDay(JSON.parse(response.data.data[0].days))
     
    } else {
    }
  };
  const handleCruiseEnquiry = async () => {
    const jobDetailData = {
    
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      date: date,
      description: message,
      
    };
    const response = await addEnquiryDetail(jobDetailData);

    if (response.remote === "success") {
      
          swal("Enquiry Send");
          setFirstName('')
          setLastName('')
          setEmail('')
          setPhone('')
          setDate('')
          setMessage('')
    } else {
    }
  };
  var experienceSlide = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,

    slidesToShow: 1,
    slidesToScroll: 1,
  };
 
  const navigate = useNavigate();
  useEffect(() => {
    const catagoryId = searchParams.get("package");
    handleCruisePackage(catagoryId)
  }, [searchParams])

  const [experienceListArray, SetExperienceListArray] = useState([]);
const handleExperienceList = async () => {
  setIslodingData(true)
  const response = await showExperienceList();

  if (response.remote === "success") {
    setTimeout(() => {
      setIslodingData(false);
    }, 3000);
    SetExperienceListArray(response.data.data);
  } else {
  }
};

useEffect(() => {
  handleExperienceList()
}, [])
const isFoundOne = cruiseEnquiryDay.some(element => {
  if (element.dayCount === "Day One") {
    return true;
  }

  return false;
});
const isFoundTwo = cruiseEnquiryDay.some(element => {
  if (element.dayCount === "Day Two") {
    return true;
  }

  return false;
});
const isFoundThree = cruiseEnquiryDay.some(element => {
  if (element.dayCount === "Day Three") {
    return true;
  }

  return false;
});
const isFoundFour = cruiseEnquiryDay.some(element => {
  if (element.dayCount === "Day Four") {
    return true;
  }

  return false;
});
const isFoundFive = cruiseEnquiryDay.some(element => {
  if (element.dayCount === "Day Five") {
    return true;
  }

  return false;
});
const isFoundSix = cruiseEnquiryDay.some(element => {
  if (element.dayCount === "Day Six") {
    return true;
  }

  return false;
});
const isFoundSeven = cruiseEnquiryDay.some(element => {
  if (element.dayCount === "Day Seven") {
    return true;
  }

  return false;
});
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Boutique Hotels, Resorts, Restaurants & Private Tours | Samadhi
          Retreats
        </title>
        <meta
          name="description"
          content="Discover your next travel destination with  Samadhi Retreats, where curated stays, and unforgettable travel experiences await. Book Now"
        />
      </Helmet>
      {isLoadingData ? <LoaderMain /> : ""}

      <div className="room-type-page cruise-charter">
        <div className="main-bg">
          <Container maxWidth="xl">
            {/* <SubHeader
              pageTitle="Cruises"
              subHeading={[
                { id: "1", subLink: "Cruises", subUrl: "/samadhi-cruises" },
                {
                  id: "2",
                  subLink: "Activities",
                  subUrl: "/cruises-activities",
                },
                {
                  id: "3",
                  subLink: "Enquiry",
                  subUrl: "/cruise/cruise-charter",
                },
              ]}
            /> */}
            <div className="sub-header">
              <Row className="g-0">
                <Col
                  lg={6}
                  className="d-flex justify-content-md-end  justify-content-sm-center sub-header-title"
                >
                  <h3 onClick={() => navigate("/samadhi-cruises")}>
                    {/* {retreatsDetails.title} */}
                    Cruises
                    <span className="ms-2">{subMenuArrow}</span>
                  </h3>
                </Col>
                <Col lg={6}>
                  <ul className="ps-0 pt-20 pb-15">
                    <li>
                      {/* <Link to="#" className="active">{item.subLink}</Link> */}
                      <Button
                        className="active"
                        onClick={() =>
                          navigate("/samadhi-cruises?category=package")
                        }
                      >
                        Packages
                      </Button>
                    </li>
                    <li>
                      {/* <Link to="#" className="active">{item.subLink}</Link> */}
                      <Button
                        onClick={() =>
                          navigate("/samadhi-cruises?category=activity")
                        }
                      >
                        Activities
                      </Button>
                    </li>
                    {/* <li>
                      <Link to="#" className="active">{item.subLink}</Link>
                      <Button
                        onClick={() => navigate(`/cruise/cruise-charter?package=${cruiseEnquiryData?.id}`)}
                        className="active"
                      >
                        Enquiry
                      </Button>
                    </li> */}
                  </ul>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="travel-motorbike">
            <Container maxWidth="xl">
              <div className="border-dashed-ex"></div>
              <h3>{cruiseEnquiryData?.title}</h3>
            </Container>
            <Container maxWidth="xl" className="banner_container">
              <div
                className="banner-div"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <BannerSlider BannerArray={cruiseEnquiryGallery} />
              </div>
            </Container>
          </div>
          <div className="moterbike-map">
            <Container maxWidth="xl">
              <div className="border-dashed-ex map-size-circle">
                <Grid container spacing={0}>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    data-aos="fade-up"
                    data-aos-once="true"
                    data-aos-duration="3000"
                  >
                    <img src={`${Offers_URL}${cruiseEnquiryData?.mapImage}`} />
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>

          <div className="moterbike-tour-section">
            <Container maxWidth="xl">
              <div className="border-btm content-moterbike day-content">
                <Grid container spacing={3}>
                  <Grid item xl={4} lg={4}>
                    <Stack direction="column" spacing={4}>
                      <div
                        className="pacakage-duration"
                        // data-aos="fade-up"
                        // data-aos-duration="3000"
                      >
                        <p
                          dangerouslySetInnerHTML={{
                            __html: cruiseEnquiryData?.package,
                          }}
                        />
                      </div>
                    </Stack>
                  </Grid>
                  <Grid item xl={4} lg={4}>
                    <Stack direction="column" spacing={4}>
                      <div
                        className="moterbike-day day-div"
                        // data-aos="fade-down"
                        // data-aos-duration="3000"
                      >
                        <h3>{isFoundOne ? "Day One" : ""}</h3>
                        {cruiseEnquiryDay?.map((item) => {
                          return (
                            item.dayCount === "Day One" && (
                              <>
                                <ul>
                                  <li>
                                    <p>{item.dayDescription}</p>
                                    <p>{item.dayTime}</p>
                                  </li>
                                </ul>
                              </>
                            )
                          );
                        })}
                      </div>
                      <div
                        className="moterbike-day day-div"
                        // data-aos="fade-up"
                        // data-aos-duration="3000"
                      >
                        <h3>{isFoundTwo ? "Day Two" : ""}</h3>
                        {cruiseEnquiryDay?.map((item) => {
                          return (
                            item.dayCount === "Day Two" && (
                              <>
                                <ul>
                                  <li>
                                    <p>{item.dayDescription}</p>
                                    <p>{item.dayTime}</p>
                                  </li>
                                </ul>
                              </>
                            )
                          );
                        })}
                        <h3>{isFoundThree ? "Day Three" : ""}</h3>
                        {cruiseEnquiryDay?.map((item) => {
                          return (
                            item.dayCount === "Day Three" && (
                              <>
                                <ul>
                                  <li>
                                    <p>{item.dayDescription}</p>
                                    <p>{item.dayTime}</p>
                                  </li>
                                </ul>
                              </>
                            )
                          );
                        })}
                      </div>
                    </Stack>
                  </Grid>
                  <Grid item xl={4} lg={4}>
                    <Stack direction="column" spacing={4}>
                      <div
                        className="moterbike-day day-div"
                        // data-aos="fade-up"
                        // data-aos-duration="3000"
                      >
                        <h3>{isFoundFour ? "Day Four" : ""}</h3>
                        {cruiseEnquiryDay?.map((item) => {
                          return (
                            item.dayCount === "Day Four" && (
                              <>
                                <ul>
                                  <li>
                                    <p>{item.dayDescription}</p>
                                    <p>{item.dayTime}</p>
                                  </li>
                                </ul>
                              </>
                            )
                          );
                        })}
                        <h3>{isFoundFive ? "Day Five" : ""}</h3>
                        {cruiseEnquiryDay?.map((item) => {
                          return (
                            item.dayCount === "Day Five" && (
                              <>
                                <ul>
                                  <li>
                                    <p>{item.dayDescription}</p>
                                    <p>{item.dayTime}</p>
                                  </li>
                                </ul>
                              </>
                            )
                          );
                        })}
                      </div>
                      <div
                        className="moterbike-day day-div"
                        // data-aos="fade-up"
                        // data-aos-duration="3000"
                      >
                        <h3>{isFoundSix ? "Day Six" : ""}</h3>
                        {cruiseEnquiryDay?.map((item) => {
                          return (
                            item.dayCount === "Day Six" && (
                              <>
                                <ul>
                                  <li>
                                    <p>{item.dayDescription}</p>
                                    <p>{item.dayTime}</p>
                                  </li>
                                </ul>
                              </>
                            )
                          );
                        })}
                        <h3>{isFoundSeven ? "Day Seven" : ""}</h3>
                        {cruiseEnquiryDay?.map((item) => {
                          return (
                            item.dayCount === "Day Seven" && (
                              <>
                                <ul>
                                  <li>
                                    <p>{item.dayDescription}</p>
                                    <p>{item.dayTime}</p>
                                  </li>
                                </ul>
                              </>
                            )
                          );
                        })}
                      </div>
                    </Stack>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
          <div className="price-section question-answer-div-main">
            <Container maxWidth="xl">
              <div className="price-content-box">
                <Grid container spacing={3}>
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    // data-aos="fade-up"
                    // data-aos-duration="3000"
                  >
                    <div className=" inclusions  ">
                      <h3>{cruiseEnquiryData?.second_title}</h3>
                      <div className="mb-3">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: cruiseEnquiryData?.inclusions,
                          }}
                        />
                      </div>
                      <h3>{cruiseEnquiryData?.first_title}</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: cruiseEnquiryData?.stay,
                        }}
                      />
                    </div>
                  </Grid>

                  <Grid
                    item
                    xl={8}
                    lg={8}
                    // data-aos="fade-down"
                    // data-aos-duration="3000"
                  >
                    <div className="faq-div">
                      <h3>F&Q</h3>
                      <ul>
                        {cruiseEnquiryFaq?.map((item) => (
                          <>
                            <li>
                              <p className="question-set">{item.question}</p>
                              <p className="answer-set">{item.answer}</p>
                            </li>
                          </>
                        ))}
                      </ul>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>

          <div className="enquiry-form">
            <Container maxWidth="xl">
              <div
                className="enquirt-section border-btm"
                // data-aos="fade-up"
                // data-aos-duration="3000"
              >
                <Grid container spacing={0} justifyContent="center">
                  <Grid item xl={8} lg={8} xs={12}>
                    <h3 className="text-center">Enquiry</h3>
                    <Stack direction="column" spacing={2}>
                      <input
                        placeholder="First Name"
                        className="enquiry-input"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                      />
                      <input
                        placeholder="Last Name"
                        className="enquiry-input"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                      />
                      <input
                        placeholder="Email"
                        className="enquiry-input"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                      <input
                        placeholder="Phone number"
                        className="enquiry-input"
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        type="number"
                      />
                      <input
                        placeholder="Estimated tour date"
                        className="enquiry-input"
                        onChange={(e) => setDate(e.target.value)}
                        value={date}
                        type="date"
                      />
                      <textarea
                        placeholder="Message"
                        className="enquiry-textarea"
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                      />
                      <button
                        className="btn-send"
                        onClick={() => handleCruiseEnquiry()}
                      >
                        Send
                      </button>
                    </Stack>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
          <div className="experience-div-section">
            <Container maxWidth="xl">
              <Grid className="text-center section-head">
                <h3>Experiences</h3>
              </Grid>
              <Slider {...experienceSlide} className="experience-slider">
                {experienceListArray?.map((item, index) => (
                  <Grid className="testimonial-type-card">
                    <Grid
                      container
                      spacing={{ md: 6, xs: 2 }}
                      className="align-items-center d-flex "
                      data-aos="fade-up"
                      data-aos-once="true"
                      data-aos-duration="10000"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={4}>
                        <div>
                          {" "}
                          <Grid className="cards heading">
                            <h4>{item.title}</h4>
                            <p>{item.shortdescription}</p>
                            <Link to="#">Discover</Link>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <img src={`${Offers_URL}${item.banner}`} alt="" />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Slider>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};
export default CruiseCharter
