import React from "react";
import PropTypes from "prop-types";
import Content from "./content";
import Image from "./image";
import { Grid } from "@mui/material";

function TestimonialSlider(props) {
  return (
    <>
      <Grid className="testimonial-type-card">
        <Grid
          container
          spacing={{ md: 6, xs: 2 }}
          className="align-items-center d-flex "
          data-aos="fade-up"
          data-aos-duration="10000"
          alignItems="center"
        >
          <Grid item xs={12} md={4}>
            <Content {...props} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Image {...props} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

TestimonialSlider.propTypes = {
  image: PropTypes.string.isRequired,
  // type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default TestimonialSlider;
