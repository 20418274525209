import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Box, Button, Typography } from "@mui/material";

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Check if the consent has already been given
  useEffect(() => {
    const cookieConsent = Cookies.get("cookie_consent");
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    // Set a cookie to remember the user's consent for 365 days
    Cookies.set("cookie_consent", "true", { expires: 365 });
    setIsVisible(false);
  };

  if (!isVisible) return null; // Don't render if the banner is not visible

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: "#000",
        color: "#fff",
        p: 2,
        textAlign: "center",
        zIndex: 1000,
      }}
    >
      <Typography variant="body2" sx={{ mb: 1 }}>
      We use cookies to enhance your experience on our website and to help us better understand how you use it. By continuing to browse, you consent to the use of cookies in accordance with our Privacy Policy. You can adjust your preferences at any time.{" "}
        <a href="/privacy-notes" style={{ color: "#90caf9", textDecoration: "underline" }}>
          Privacy Policy
        </a>.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleAccept}>
        Accept
      </Button>
    </Box>
  );
};

export default CookieConsent;
