import logo from "./logo.svg";
import "./App.css";
import "./assets/css/responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./components/layout/Layout";
import { Routes, Route, Navigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "react-lazy-load-image-component/src/effects/blur.css";

// import "../src/assets/css/aos.css";

import "../node_modules/aos/dist/aos.css";
import { ParallaxProvider } from "react-scroll-parallax";

import { useEffect } from "react";
import ErrorPage from "./errorPage";
function App() {
  useEffect(() => {
    AOS.init();

    function refreshAOS() {
      AOS.refresh();
    }

    window.addEventListener("scroll", refreshAOS);

    return () => {
      window.removeEventListener("scroll", refreshAOS);
    };
  }, []);
  const token = localStorage.getItem("token");
  return (
    <>

      <div className="mainLoader">
      </div>
      <div className="App">
        <ParallaxProvider>
          <Routes>
           <Route path="/japamalaresorts" element={<Navigate to="/japamala-resort-tioman-island?catagoryId=17" replace />} />

            <Route path="/error" element={<ErrorPage />} />
            {/* <Route path="*" exact={true} element={<ErrorPage />} /> */}
            <Route path="/*" element={<Layout />} />
             {/* <Navigate from="/" to="/404" />  */}
             <Route path="/admin" exact={true} element={<ErrorPage />} />
             <Route path="/admin/*" exact={true} element={<ErrorPage />} />
             <Route path="*" element={<ErrorPage />} />
          </Routes>
        </ParallaxProvider>
      </div>
    </>
  );
}

export default App;
