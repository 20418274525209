import { React, useEffect } from "react";
import SubHeader from "../../components/layout/SubHeader";
import { Button, Grid } from "@mui/material";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import Slider from "react-slick";
import BannerSlider from "../../components/BannerSlider";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ExclusiveCard from "../VSKL/ExclusiveCard";

const {
  exclusiveBanner,
  off1,
  off2,
  off3,
  vsklOne,
  picleftThree,
  vsklTwo,
  vsklT,
  vsklO,
  vsklTaste,
  vsklBanner,
  jungleMan,
  vsklTasteBanner,
  resturentNew,
  building,
  taste3,
  cooking,
} = IMAGES;
const BannerArray = [vsklTasteBanner, vsklBanner];
const restaurentList = [
  {
    image: off1,
    type: "STAYS",
    title: "Time for two at Japamala",
    description:
      "Planning the perfect celebration begins with selecting your dream venue. Encircled by the raw beauty of nature, this jungle-luxe restaurant’s long meditative walkway, treetop vistas, water features and stone-carved statues exude Asian elegance and a warm and welcoming ambience.",
    id: "1",
    speed: "2000",
    dataspeed: "fade-up",
  },
  {
    image: off2,
    type: "TASTES",
    title: "Fook on Tuesday",
    description:
      "Set among the leafy groves of KL’s affluent embassy district, Villa Samadhi is the perfect urban escape…",
    id: "2",
    speed: "3000",
    dataspeed: "fade-down",
  },
  {
    image: building,
    type: "TRAVELS",
    title: "Kuala Lumpur Citi Tours",
    description:
      "Set among the leafy groves of KL’s affluent embassy district, Villa Samadhi is the perfect urban escape…",
    id: "3",
    speed: "3000",
    dataspeed: "fade-up",
  },
  {
    image: off1,
    type: "STAYS",
    title: "Time for two at Japamala",
    description:
      "Planning the perfect celebration begins with selecting your dream venue. Encircled by the raw beauty of nature, this jungle-luxe restaurant’s long meditative walkway, treetop vistas, water features and stone-carved statues exude Asian elegance and a warm and welcoming ambience.",
    id: "4",
    speed: "4000",
    dataspeed: "fade-up",
  },
];
const tasteList = [
  {
    image: vsklOne,
    type: "OFFERS",
    title: "Samadhi Indulgence",
    description:
      "The ultimate treat for two. With inclusions that are meant to indulge our guests, immerse yourself in a transcendental Samadhi Experience like no other from food and beverages..",
    id: "1",
    speed: "1000",
    dataspeed: "fade-up",
  },
  {
    image: vsklTaste,
    type: "SOMA WELLNESS",
    title: "A wellness spa in the heart of the wild",
    description:
      "SOMA is your source of wellness. A health system that embraces a holistic approach to total healing of mind, body and soul through a combination of ancient yogic practices",
    id: "2",
    speed: "2000",
    dataspeed: "fade-down",
  },
  {
    image: jungleMan,
    type: "STORY",
    title: "The Thaipusam Experience",
    description:
      "Witness the chaos and wonder of Thaipusam, the Hindu Tamil festival. Follow the throng of bare-foot devotees as they make their temple pilgrimage accompanied by rhythmic music, ",
    id: "3",
    speed: "3000",
    dataspeed: "fade-up",
  },
  {
    image: cooking,
    type: "EXPERIENCES",
    title: "Street Foods",
    description:
      "Witness the chaos and wonder of Thaipusam, the Hindu Tamil festival. Follow the throng of bare-foot devotees as they make their temple pilgrimage accompanied by rhythmic music,",
    id: "1",
    speed: "3000",
    dataspeed: "fade-up",
  },
];
const exclusiveOfferArray = [
  {
    image: exclusiveBanner,
    title: "3 Days 2 Nights  Indulgence",
    feature_title: "Package Inclusions",
    description:
      "The ultimate treat for two. With inclusions that are meant to indulge our guests, immerse yourself in a transcendental Samadhi Experience like no other from food and beverages, to transfers and heritage walk and to wrap up the affair with a local souvenir. Package includes the Samadhi Experience, breakfast, dinner, airport transfer, heritage walk, a therapeutic Wellness treatment session and a complimentary bottle of wine.",
    package_feature: [
      "Daily breakfast for 2 persons",
      "Complete Samadhi Experience for 2 persons",
      "Complimentary return airport transfers to or from KLIA with Standard Sedan (Additional 50% surcharge applied for midnight transfer from 10PM - 7AM)",
      " Complimentary one-time 4-hour Heritage Walk for 2 persons (Guest can choose between morning or evening walk)",
      " Complimentary one-time 60-minutes Wellness treatment for 2 persons ",
      " Complimentary one-time set dinner for 2 persons",
      " Complimentary local souvenir ",
      "One MYR 50 dining or spa credit per stay with a minimum of MYR 100 spending ",
    ],
    id: "1",
    speed: "2000",
    dataspeed: "fade-up",
  },
];
const exclusiveOfferArrayTwo = [
  {
    image: picleftThree,
    title: "3 Days 2 nights Nesting at Japamala",
    feature_title: "Package Inclusions",
    description:
      "The ultimate treat for two. With inclusions that are meant to indulge our guests, immerse yourself in a transcendental Samadhi Experience like no other from food and beverages, to transfers and heritage walk and to wrap up the affair with a local souvenir. Package includes the Samadhi Experience, breakfast, dinner, airport transfer, heritage walk, a therapeutic Wellness treatment session and a complimentary bottle of wine.",
    package_feature: [
      "Daily breakfast for 2 persons",
      "Complete Samadhi Experience for 2 persons",
      "Complimentary return airport transfers to or from KLIA with Standard Sedan (Additional 50% surcharge applied for midnight transfer from 10PM - 7AM)",
      " Complimentary one-time 4-hour Heritage Walk for 2 persons (Guest can choose between morning or evening walk)",
      " Complimentary one-time 60-minutes Wellness treatment for 2 persons ",
      " Complimentary one-time set dinner for 2 persons",
      " Complimentary local souvenir ",
      "One MYR 50 dining or spa credit per stay with a minimum of MYR 100 spending ",
    ],
    id: "1",
    speed: "1000",
    dataspeed: "fade-up",
  },
];
export default function GiftCardExperience() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  var tasteSlider = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisbile: false,
      
    },
  };
  return (
    <>
      <div className="vskl-ex-offer gift-card-experience">
        <div className=" main-bg">
          <Container maxWidth="xl">
            <div className="sub-header-div">
              <SubHeader
                pageTitle="Gift Card "
                subHeading={[
                  {
                    id: "0",
                    subLink: "Digital Gift Card",
                    subUrl: "/digital-gift-card",
                  },
                  {
                    id: "1",
                    subLink: "Story",
                    subUrl: "/gift-card-experience",
                  },
                ]}
              />
            </div>
          </Container>

          <div className="">
            <Container maxWidth="xl">
              <div className="exclusive-newnew pb-80">
                <Grid className="text-center section-head">
                  <h3 className="geting-text">Gift Card - Experiences</h3>
                </Grid>
                {exclusiveOfferArray.map((item, index) => (
                  <div
                    key={index}
                    data-aos={item.dataspeed}
                    data-aos-duration={item.speed}
                    className="bottom-cards-div mt-100"
                  >
                    <ExclusiveCard {...item} btnName="Purchase" />
                  </div>
                ))}
              </div>
              <div className="exclusive-newnew ">
                {exclusiveOfferArrayTwo.map((item, index) => (
                  <div
                    key={index}
                    data-aos={item.dataspeed}
                    data-aos-duration={item.speed}
                    className="bottom-cards-div px-3 mt-100"
                  >
                    <ExclusiveCard {...item} btnName="Purchase" />
                  </div>
                ))}
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
