import { React, useEffect } from "react";
import SubHeader from "../../components/layout/SubHeader";
import { Button, Grid } from "@mui/material";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import Slider from "react-slick";
import BannerSlider from "../../components/BannerSlider";
import { Link, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ExclusiveCard from "../VSKL/ExclusiveCard";
import { digitalgiftData, listData } from "../../api/frontend";
import { useState } from "react";
import { Featured_URL } from "../../serverUrl";
import swal from "sweetalert";
import AlertModal from "../../components/AlertModal";
import LoaderMain from "../../admin/component/loader";

const {
  digitalPic,
  off1,
  off2,

  vsklOne,

  vsklTaste,
  vsklBanner,
  jungleMan,
  vsklTasteBanner,

  building,

  cooking,
} = IMAGES;

export default function DigitalGiftCard() {
  const [giftsdata, setGiftData] = useState([]);
  const [listAllData, setListAllData] = useState([]);
  const [valuedata, setValue] = useState("");
  const [locationdata, setLocation] = useState("malaysia");
  const [categorydata, setCategory] = useState("");
  const [qunatitydata, setQuantity] = useState(1);
  const [valueupdate, setValueUpdate] = useState(1);
const [isLoadingData, setIslodingData] = useState(true);

  const navigate = useNavigate();
  const [alertMessageModal, setAlertMessageModal] = useState(false);
  const handleShowAlertModal = (type) => {
    setAlertMessageModal(true);
  };
  const handleAlertMessageHide = () => {
    setAlertMessageModal(false);
  };

  const handleGiftcard = async () => {
    setIslodingData(true);

    const response = await digitalgiftData();

    if (response.remote === "success") {
setTimeout(() => {
        setIslodingData(false);
      }, 3000);

      setGiftData(response.data.data);
    } else {
    }
  };
  const handlelist = async () => {
    setIslodingData(true);

    const response = await listData();

    if (response.remote === "success") {
setTimeout(() => {
        setIslodingData(false);
      }, 3000);

      setListAllData(response.data.data);
    } else {
    }
  };

  useEffect(() => {
    handleGiftcard();
    handlelist()
  }, []);

  const handlepayment = (id, image) => {
    if (valuedata && locationdata && categorydata && qunatitydata) {
      navigate("/digital-gift-card-booking", {
        state: {
          id,
          valuedata,
          locationdata,
          categorydata,
          qunatitydata,
          image,
        },
      });
    } else {
      // swal("Please select All required fields");
      handleShowAlertModal();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  var tasteSlider = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const handleValue = (num, status) => {
    alert(status);
    alert(num);

    if (status === "on") {
      setValueUpdate(Number(num) + 1);
    } else {
      setValueUpdate(Number(num) - 1);
    }
    alert(valueupdate);
  };
  return (
    <>
      {isLoadingData ? <LoaderMain /> : ""}

      <div className="vskl-ex-offer digital-gift-card">
        <div className=" main-bg">
          <Container maxWidth="xl">
            <div className="sub-header-div">
              <SubHeader
                pageTitle="Gifts"
                subHeading={[
                  {
                    id: "0",
                    subLink: "Digital Gift Card",
                    subUrl: "/digital-gift-card",
                  },
                  // {
                  //   id: "1",
                  //   subLink: "Story",
                  //   subUrl: "/gift-card-experience",
                  // },
                ]}
              />
            </div>
          </Container>

          <div className="">
            <Container maxWidth="xl">
              <div className="exclusive-newnew ">
                <Grid className="text-center section-head">
                  <h3 className="geting-text">Digital Gift Card</h3>
                </Grid>
                <Grid container justifyContent="center" spacing={4}>
                  <Grid item md={10}>
                    {" "}
                    {giftsdata.map((item, index) => (
                      <Grid className="testimonial-type-card mt-100">
                        <Grid
                          container
                          spacing={6}
                          data-aos="fade-up"
                          data-aos-duration="3000"
                        >
                          {" "}
                          <Grid item md={7}>
                            <div className="gift-image-section pt-200">
                              <Slider {...tasteSlider}>
                                {JSON.parse(item?.image)?.map((items) => (
                                  <img src={`${Featured_URL}${items}`} alt="" />
                                ))}
                              </Slider>
                              {/* <img src={item.image} alt="" className="pt-200" /> */}
                            </div>
                          </Grid>
                          <Grid item md={5}>
                            <Grid className="cards heading exclusive-card-data">
                              <h4>{item.heading}</h4>
                              <p className="mb-5">{item.subheading}</p>
                              {/* <input
                                placeholder="Dinings or Retreats"
                                className="enquiry-input w-100 mb-3"
                              />{" "} */}
                              <select
                                className="enquiry-input w-100 mb-3"
                                onChange={(e) => setLocation(e.target.value)}
                              >
                                <option selected disabled>
                                  Location
                                </option>
                                <option value="malaysia">Malasiya</option>
                                <option value="singapore">Singapore</option>
                                {/* {JSON.parse(item?.location)?.map((items) => (
                                  <option value={items.location}>
                                    {items.location}{" "}
                                  </option>
                                ))} */}
                              </select>
                              <select
                                className="enquiry-input w-100 mb-3"
                                onChange={(e) => setCategory(e.target.value)}
                              >
                                <option selected disabled>
                                  Dinings or Retreats
                                </option>
                                {listAllData?.map((items) => {
                                  if (items.location === locationdata)
                                    return (
                                      <option value={items.id}>
                                        {items.title}{" "}
                                      </option>
                                    );
                                })}
                              </select>
                              {/* <input
                                placeholder="Location"
                                className="enquiry-input w-100 mb-3"
                              />{" "} */}

                              {/* <input
                                placeholder="Please select value"
                                className="enquiry-input w-100  "
                              /> */}
                              <select
                                className="enquiry-input w-100"
                                onChange={(e) => setValue(e.target.value)}
                              >
                                <option selected disabled>
                                  Please select value
                                </option>
                                {JSON.parse(item?.value)?.map((items) => (
                                  <option value={items.text}>
                                    {items.text}{" "}
                                  </option>
                                ))}
                              </select>
                              <div className="d-flex purchase-div mb-5">
                                <div className="d-flex">
                                  <span
                                    className="small-btn-inc mt-5 me-3 cursor-btn"
                                    onClick={() =>
                                      setQuantity(
                                        qunatitydata === 1
                                          ? 1
                                          : qunatitydata - 1
                                      )
                                    }
                                  >
                                    -
                                  </span>
                                  <input
                                    className="white-input-small mt-5 me-3"
                                    placeholder="1"
                                    value={qunatitydata}
                                    // type="number"
                                    onChange={(e) =>
                                      setQuantity(Number(e.target.value))
                                    }
                                  />{" "}
                                  <span
                                    className="small-btn-inc mt-5 me-3 cursor-btn"
                                    onClick={() =>
                                      setQuantity(qunatitydata + 1)
                                    }
                                  >
                                    +
                                  </span>
                                </div>
                                <Button
                                  disabled
                                  // to="/digital-gift-card-booking"
                                  onClick={() => {
                                    handlepayment(item.id, item?.image);
                                  }}
                                  className="dashed-btn mt-5"
                                >
                                  Purchase
                                </Button>
                              </div>
                              <p>{item.discription}</p>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
        </div>
        <AlertModal
          alertMessageModal={alertMessageModal}
          handleAlertMessageHide={handleAlertMessageHide}
          alertMessageContent={"Please select All required fields"}
          colorAlert={"text-danger"}
        />
      </div>
    </>
  );
}
