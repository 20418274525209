import Homepage from './pages/Homepage'
import RoomType from './pages/VSKL/RoomType'
import Travels from "./pages/Travel";
import TasteHomePage from "./pages/Taste";
import Stays from "./pages/Stays";
import ExperienceHome from "./pages/Experience";
import TravelCategory from "./pages/Travel/travelCategory";
import ExclusiveHomePage from "./pages/ExclusiveOffer";
import VSKLHome from "./pages/VSKL";
import TamarindSpring from "./pages/Taste/TamarindSpring";
import VSKLGallery from "./pages/VSKL/vsklGallery";
import VSKLGalleryOne from "./pages/VSKL/vsklGalleryOne";
import Cruises from "./pages/Cruises";
import CelebrationEvent from "./pages/Celebration_&_Event";
import VSKLTaste from "./pages/VSKL/vsklTaste";
// import Accolades from "./pages/Accolades/Accolades";

import CelebrationEventSingapore from "./pages/Celebration_&_Event/Celebration&EventSingapore";
import TamarindHillSingaporeTwo from "./pages/Celebration_&_Event/TamarindHillSingapore";

import CruiseYatch from "./pages/Cruises/CruiseYatch";
import VSKLExclusiveOffer from "./pages/VSKL/vsklExclusiveOffer";
import VSKLThaipusamExperience from "./pages/VSKL/VSKLThaipusamExperience";
import VSKLThaipusamExperienceOne from "./pages/Experience/experience-one";
import VsklRoomType2 from "./pages/VSKL/VsklRoomType2";
import TravelMotorbikeTours from "./pages/Travel/travel-motorbike-tours";
import CelebrationEventsTamarindHillSingapore1 from "./pages/Celebration_&_Event/celebration-events-tamarind-hill-singapore-1";
import CelebrationEventMalasiya from "./pages/Celebration_&_Event/celebration-event-malasiya";
import CruiseCharter from "./pages/Cruises/cruiseCharter";
import AboutUs from "./pages/AboutUs";
import DigitalGiftCard from "./pages/GiftCard/digitalGiftCard";
import GiftCardExperience from "./pages/GiftCard/giftCardExperience";
import Contactus from "./pages/ContactUs";
import JobOpportunityApply from "./pages/JobOpportunity/jobOpportunityApply";
import JobOpportunity from "./pages/JobOpportunity";
import TamarindHillSingapore from "./pages/Celebration_&_Event/TamarindHillSingaporeTwo";
import Accolades from './pages/Accolades';
import PrivacyNotes from './pages/privacyNotes';
import DigitalGiftCardBooking from './pages/GiftCard/digitalGiftCardBooking';
import VSKLExclusiveOfferOne from "./pages/VSKL/vsklExclusiveOfferOne";
import VSKLCelebration from "./pages/VSKL/vcsklCelebration";
import VSKLExclusiveOfferCategory from "./pages/VSKL/vsklExclusiveOfferCategory";
import Sustainability from "./pages/sustainability";
import RetreatsDetail from "./pages/VSKL/retreatsDetail";
import CarnivalTicket from "./pages/Ticket/CarnivalText";

// import Travels from "../components/pages/Travels";
const routes = [
  {
    path: "/",
    exact: true,
    name: "home",
    component: <Homepage />,
  },
  {
    path: "/:slug/rooms",
    exact: true,
    name: "villasamadhikl-accomodation",
    component: <RoomType />,
  },
  {
    path: "/travels-tours",
    exact: true,
    name: "travels-home",
    component: <Travels />,
  },
  {
    path: "/travels/:id",
    expect: true,
    name: "travels-motorbike-tours-packages-enquiry",
    component: <TravelMotorbikeTours />,
  },
  {
    path: "/boutique-hotels-villas-resorts",
    exact: true,
    name: "retreats-home",
    component: <Stays />,
  },

  {
    path: "/travels-motorbike-tours-packages",
    exact: true,
    name: "travels-motorbike-tours-packages",
    component: <TravelCategory />,
  },
  {
    path: "/samadhi-experience",
    exact: true,
    name: "experiences-home",
    component: <ExperienceHome />,
  },
  {
    path: "/fine-dining-restaurants-kuala-lumpur-singapore",
    exact: true,
    name: "taste-home",
    component: <TasteHomePage />,
  },
  {
    path: "/:slug/:id",
    exact: true,
    name: "tastes-kl-tamarind-springs",
    component: <TamarindSpring />,
  },
  /*=-=-=-=-=-=-=-=-offers-=-=-=-=-=-*/
  {
    path: "/offers",
    exact: true,
    name: "exclusive-offers",
    component: <ExclusiveHomePage />,
  },

  /*-=-=--=--=-=-=-=-=-=--=vskl pages routes-=-=-=-=-=-=-*/
  {
    path: "/:slug",
    exact: true,
    name: "vskl-home",
    component: <VSKLHome />,
  },
  {
    path: "/:slug/gallery",
    exact: true,
    name: "vskl-gallery",
    component: <VSKLGallery />,
  },
  {
    path: "/vskl-gallery-one",
    exact: true,
    name: "vskl-gallery-one",
    component: <VSKLGalleryOne />,
  },
  {
    path: "/:slug/dining",
    exact: true,
    name: "villa-samadhi-kl-dining",
    component: <VSKLTaste />,
  },
  {
    path: "/offers/:id",
    exact: true,
    name: "exclusive-offer",
    component: <VSKLExclusiveOffer />,
  },
  {
    path: "/exclusive-offer-details",
    exact: true,
    name: "exclusive-offer",
    component: <VSKLExclusiveOfferOne />,
  },
  {
    path: "/exclusive-offer-villa-samadhi-kualalumpur-1",
    exact: true,
    name: "exclusive-offer-villa-samadhi-kualalumpur-1",
    component: <VSKLExclusiveOfferOne />,
  },

  {
    path: "/experiences-details",
    expect: true,
    name: "experiences-detials",
    component: <VSKLThaipusamExperience />,
  },
  {
    path: "/experiences-villa-samadhi-sg-lifeatthepark",
    expect: true,
    name: "experiences-villa-samadhi-sg-lifeatthepark",
    component: <VSKLThaipusamExperienceOne />,
  },
  {
    path: "/:slug/rooms/:id",
    expect: true,
    name: "villa-samadhi-kl-accomodation-crib",
    component: <VsklRoomType2 />,
  },
  /*-=-=-=-=-=-=-=-=cruises pages routess=-=-=-=-=-=*/
  {
    path: "/samadhi-cruises",
    exact: true,
    name: "cruises-home",
    component: <Cruises />,
  },
  {
    path: "/cruises-activities",
    exact: true,
    name: "/cruises-activities",
    component: <CruiseYatch />,
  },
  {
    path: "/cruise/cruise-charter",
    exact: true,
    name: "cruise-charter",
    component: <CruiseCharter />,
  },
  /*-=-=-=-=-=-=-=-=-=-=Celebration and event =-=-=-=-=-=-=-*/
  {
    path: "/weddings-events-kuala-lumpur",
    exact: true,
    name: "celebration-and-event-home",
    component: <CelebrationEvent />,
  },
  {
    path: "/weddings-events-singapore",
    exact: true,
    name: "celebration-event-singapore",
    component: <CelebrationEventSingapore />,
  },
  {
    path: "/weddings-events-malaysia",
    exact: true,
    name: "celebration-event-malaysia",
    component: <CelebrationEventMalasiya />,
  },
  {
    path: "/celebration-event/:id",
    exact: true,
    name: "celebration-event/:id",
    component: <TamarindHillSingapore />,
  },
  {
    path: "/celebration-event-tamarind-singapore",
    exact: true,
    name: "/celebration-event-tamarind-singapore",
    component: <TamarindHillSingaporeTwo />,
  },
  {
    path: "/celebration-and-events-enquiry",
    expect: true,
    name: "celebration-and-events-enquiry",
    component: <CelebrationEventsTamarindHillSingapore1 />,
  },

  /*-=-=-=-=-=-=-=-=-=-=-About us-=-=-=-=-=-=-=-=*/
  {
    path: "/about-us",
    expect: true,
    name: "about-us",
    component: <AboutUs />,
  },
  /*-=-=-=-=-=-=-=-=-=-=-Gift Card-=-=-=-=-=-=-=-=*/
  {
    path: "/digital-gift-card",
    expect: true,
    name: "digital-gift-card",
    component: <DigitalGiftCard />,
  },
  {
    path: "/digital-gift-card-booking",
    expect: true,
    name: "digital-gift-card-booking",
    component: <DigitalGiftCardBooking />,
  },
  {
    path: "/gift-card-experience",
    expect: true,
    name: "gift-card-experience",
    component: <GiftCardExperience />,
  },

  /*-=-=-==-==-==-=Contact-us-=-==-=== */
  {
    path: "/contact-us",
    expect: true,
    name: "contact-us",
    component: <Contactus />,
  },
  /*-=-=-==-==-==-=Job Opportunities-=-==-=== */
  {
    path: "/apply-job/:id/:designation/:location",
    expect: true,
    name: "apply-job",
    component: <JobOpportunityApply />,
  },
  {
    path: "/job-opportunity",
    expect: true,
    name: "job-opportunity",
    component: <JobOpportunity />,
  },
  {
    path: "/accolades",
    expect: true,
    name: "accolades",
    component: <Accolades />,
  },
  {
    path: "/sustainability",
    expect: true,
    name: "sustainability",
    component: <Sustainability />,
  },
  {
    path: "/privacy-notes",
    expect: true,
    name: "privacy-notes",
    component: <PrivacyNotes />,
  },
  {
    path: "/vskl-celebration",
    expect: true,
    name: "vskl-celebration",
    component: <VSKLCelebration />,
  },
  {
    path: "/:slug/offers",
    expect: true,
    name: "vskl-exclusive-offer",
    component: <VSKLExclusiveOfferCategory />,
  },
  {
    path: "/:id/getting-here",
    expect: true,
    name: "retreats-detail",
    component: <RetreatsDetail />,
  },
  {
    path: "/samadhi/:ticket/:id",
    expect: true,
    name: "carnival-ticket",
    component: <CarnivalTicket />,
  },
];
export default routes
