import React from 'react'
import { Container } from '@mui/material'
import { useLocation, useNavigate } from "react-router-dom";

export default function TabButton() {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <div className="tab-button">
        <Container>
          <div className="justify-content-md-center">
            <div className="slider-count-text text-center">
              <span
                className={
                  location.pathname === "/weddings-events-malaysia"
                    ? "active cursor-btn"
                    : "cursor-btn"
                }
                onClick={() => navigate(`/weddings-events-malaysia?catagoryId=1`)}
              >
                Malaysia{" "}
              </span>{" "}
              /{" "}
              <span
                onClick={() => navigate(`/weddings-events-singapore?catagoryId=2`)}
                className={
                  location.pathname === "/weddings-events-singapore"
                    ? "active cursor-btn"
                    : "cursor-btn"
                }
              >
                {" "}
                Singapore
              </span>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
