import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IMAGES from "../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import {
  restaurantNameList,
  RetreatCategorySection,
  sidebarData,
} from "../../api/frontend";
import { CloseCircle, cross, subMenuArrow } from "../../utils/svg.file";
import { Offers_URL } from "../../serverUrl";
const { whiteLogo, tasteBanner, stayBanner, menuPic } = IMAGES;
const sideMenu = [
  ,
  { id: "0", linkName: "Retreats", linkUrl: "/boutique-hotels-villas-resorts" },
  // { id: "0", linkName: "Retreats", linkUrl: "#" },

  {
    id: "1",
    linkName: "Restaurants",
    linkUrl: "/fine-dining-restaurants-kuala-lumpur-singapore",
    // linkUrl: "#",
  },
  { id: "2", linkName: "Travels", linkUrl: "/travels-tours" },
  { id: "3", linkName: "Cruises", linkUrl: "/samadhi-cruises" },
  { id: "4", linkName: "Stories", linkUrl: "/samadhi-experience" },
  {
    id: "5",
    linkName: "Celebrations & Events",
    linkUrl: "/weddings-events-kuala-lumpur",
  },
  { id: "6", linkName: "Offers", linkUrl: "/offers" },
  // { id: "7", linkName: "Gifts", linkUrl: "#" },
  // { id: "7", linkName: "Gifts", linkUrl: "/digital-gift-card" },
];

export default function SideMenu(props) {
  const navigate = useNavigate();
  const [restaurantName, setRestaurantName] = useState([]);
  const [retreatName, setRetreatName] = useState([]);
  const [showMenuItem, setShowMenuItem] = useState(false);
  const [checkData, setCheckData] = useState("");
  const [sideData, setSidebarData] = useState("");

  const [isDesktopBig, setIsDesktopBig] = useState(window.innerWidth > 650);
  const handleRestaurantName = async () => {
    const response = await restaurantNameList();

    if (response.remote === "success") {
      setRestaurantName(response.data.data);
    } else {
    }
  };
  const handleRetreatName = async () => {
    const response = await RetreatCategorySection();

    if (response.remote === "success") {
      setRetreatName(response.data.data);
    } else {
    }
  };
  const [openMenu, setOpenMenu] = useState(null);
  const handleforntlistsidebar = async (i, type) => {
    setOpenMenu(i === openMenu ? null : i);
    const response = await sidebarData(type);
    if (response.remote === "success") {
      setSidebarData(response.data.data);
    } else {
    }
  };
  const updateMedia = () => {
    setIsDesktopBig(window.innerWidth > 998);
  };
  useEffect(() => {
    handleRestaurantName();
    handleRetreatName();
    handleforntlistsidebar(3, "main");
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);
  const openModal = () => {
    // props.setMobileOpen(true);
  };
  const showMenuData = (data) => {
    setCheckData(data);
    setShowMenuItem(true);
  };
  const handleMenuBack = () => {
    setShowMenuItem(false);
    setOpenMenu(null);
  };
  // useEffect(() => {
  //   handleforntlistsidebar(3,'main')
  // }, [])

  return (
    <div>
      <Grid className="nav-menu-section ">
        <Container maxWidth="xl">
          <div className="d-flex justify-content-between">
            <Link to="/">
              {" "}
              <img
                src={whiteLogo}
                alt=""
                className="logo-header"
                onClick={props.handleDrawerToggle}
                loading="lazy"
                width={185}
                height={51}
              />
            </Link>
            <span
              onClick={() => props.handleDrawerToggle()}
              className="crossSideMenu cursor-btn"
              style={{ color: "#fff", fontSize: "25px" }}
            >
              {cross}
            </span>
          </div>
          <Grid className="side-menus-div">
            <Row>
              <Col md={3}>
                <ul
                  className={`side-menu-list ${
                    !isDesktopBig && showMenuItem ? "d-none" : "d-block"
                  }`}
                >
                  {sideMenu.map((item, i) => (
                    <li
                      // key={i}
                      // className="d-flex justify-content-between align-items-center open_menu_click"
                      key={i}
                      className={`d-flex justify-content-between align-items-center open_menu_click ${
                        openMenu === i ? "open" : ""
                      }`}
                    >
                      <Link
                        to={item.linkUrl}
                        onClick={() => {
                          props.handleDrawerToggle();
                          handleMenuBack();
                        }}
                      >
                        {item.linkName}
                      </Link>

                      <span
                        // className={showMenuItem ? "rotate_arrow" : ""}
                        className={` ${
                          openMenu === i ? "rotate_arrow" : "rotate_back"
                        }`}
                        onClick={() => {
                          showMenuData(item.linkName);
                          handleforntlistsidebar(
                            i,
                            item.linkName === "Retreats"
                              ? "retreat"
                              : "restaurant"
                          );
                        }}
                      >
                        {subMenuArrow}
                      </span>
                    </li>
                  ))}
                  <li
                    style={{ color: "d9ccc5" }}
                    onMouseEnter={() => {
                      props.setClosable(true);
                    }}
                    onMouseLeave={() => {
                      props.setClosable(false);
                    }}
                    // onClick={(e) => openModal()}
                  >
                    Gifts
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                {showMenuItem ? (
                  <>
                    <div className="show_menu_data">
                      <ul className="side-menu-list">
                        <li className="d-sm-block d-lg-none back_sign_sm">
                          <Link
                            to=""
                            onClick={handleMenuBack}
                            className="d-flex align-items-center"
                          >
                            <span className="back_menu"> {subMenuArrow}</span>{" "}
                            Back
                          </Link>
                        </li>
                        {checkData === "Retreats" ? (
                          <>
                            <li>
                              <Link
                                to="/boutique-hotels-villas-resorts"
                                onClick={() => {
                                  props.handleDrawerToggle();
                                  handleMenuBack();
                                }}
                              >All Retreats</Link>
                            </li>
                            {retreatName.map((item, i) => (
                              <li
                                key={i}
                                className="d-flex justify-content-between open_menu_click"
                              >
                                <a
                                  href={`/${item.slug}?catagoryId=${item.id}`}
                                  onClick={() => {
                                    props.handleDrawerToggle();
                                    handleMenuBack();
                                  }}
                                >
                                  {item.title}, {item.other}
                                </a>
                              </li>
                            ))}
                          </>
                        ) : (
                          <>
                            <li>
                              <Link
                                to="/fine-dining-restaurants-kuala-lumpur-singapore"
                                onClick={() => {
                                  props.handleDrawerToggle();
                                  handleMenuBack();
                                }}
                              >
                                All Restaurants
                              </Link>
                            </li>
                            {restaurantName.map((item, i) => (
                              <li
                                key={i}
                                className="d-flex justify-content-between open_menu_click"
                              >
                                <a
                                  href={`/${item.slug}/${item.id}`}
                                  onClick={() => {
                                    props.handleDrawerToggle();
                                    handleMenuBack();
                                  }}
                                >
                                  {item.title}, {item.restaurant}
                                </a>
                              </li>
                            ))}
                          </>
                        )}
                      </ul>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Col>
              {/* <Col md={4} className="state_menu">
                <label className="country-title">Malaysia</label>
                <p className="small-title"> Retreats</p>
                <div className="menu-content stay-list">
                  {retreatName?.map((item, i) => {
                    return (
                      item.category === "malaysia" && (
                        <span
                          key={i}
                          onClick={() => {
                            navigate(`/${item.slug}?catagoryId=${item.id}`);
                            window.scrollTo(0, 0);
                            props.handleDrawerToggle();
                          }}
                        >
                          {item.title}, {item.other}
                          <br></br>
                        </span>
                      )
                    );
                  })}
                </div>
                <p className="small-title mt-5">Restaurants</p>
                <div className="menu-content stay-list">
                  {restaurantName?.map((item, i) => {
                    return (
                      item.other === "malaysia" && (
                        <span
                          key={i}
                          onClick={() => {
                            navigate(`/${item.slug}/${item.id}`);
                            window.scrollTo(0, 0);
                            props.handleDrawerToggle();
                          }}
                        >
                          {item.title}, {item.restaurant} <br></br>
                        </span>
                      )
                    );
                  })}
                </div>
              </Col> */}
              <Col md={5} className="state_menu">
                {sideData[0]?.type === "retreat" ? (
                  <>
                    {" "}
                    <div className="sidemenu_pic">
                      <img alt="" src={`${Offers_URL}${sideData[0]?.image}`} width={541} height={337} loading="lazy"  priority={false}  />
                      <div className="content_menu_data">
                        <p className="text_sidemenu">
                          {sideData[0]?.discription}
                        </p>
                      </div>
                    </div>
                  </>
                ) : sideData[0]?.type === "restaurant" ? (
                  <div className="sidemenu_pic">
                    <img alt="" src={`${Offers_URL}${sideData[0].image}`} width={541} height={337} loading="lazy"  priority={false} />
                    <div className="content_menu_data">
                      <p className="text_sidemenu">
                        {" "}
                        {sideData[0].discription}
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="sidemenu_pic">
                      <img alt="" src={`${Offers_URL}${sideData[0]?.image}`} width={541} height={337} loading="lazy"  priority={false} />
                      <div className="content_menu_data">
                        <p className="text_sidemenu">
                          {" "}
                          {sideData[0]?.discription}
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {/* <label className="country-title">Singapore</label>
                <p className="small-title">Retreats </p>
                <div className="menu-content stay-list">
                  {retreatName?.map((item, i) => {
                    return (
                      item.category === "singapore" && (
                        <span
                          key={i}
                          onClick={() => {
                            navigate(`/${item.slug}?catagoryId=${item.id}`);
                            window.scrollTo(0, 0);
                            props.handleDrawerToggle();
                          }}
                        >
                          {item.title}, {item.other}
                          <br></br>
                        </span>
                      )
                    );
                  })}
                </div>
                <p className="small-title mt-5">Restaurants</p>
                <div className="menu-content stay-list">
                  {restaurantName?.map((item, i) => {
                    return (
                      item.other === "singapore" && (
                        <span
                          key={i}
                          onClick={() => {
                            navigate(`/${item.slug}/${item.id}`);
                            window.scrollTo(0, 0);
                            props.handleDrawerToggle();
                          }}
                        >
                          {item.title}, {item.restaurant}
                          <br></br>
                        </span>
                      )
                    );
                  })}
                </div> */}
              </Col>
            </Row>
          </Grid>
        </Container>
      </Grid>
    </div>
  );
}
