import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import SkeletonLoader from "../skeleton";
function Content({ subcategory, title, shortDescription, id, descriptionTitle,retreat }) {
  return (
    <div>
      {" "}
      <Grid className="cards heading">
        <h6>
          {subcategory ? subcategory?.toUpperCase() : retreat}
        </h6>
        {/* <h6>{retreat}</h6> */}
        <h4>{title}</h4>
        <p className="mb-0">{descriptionTitle}</p>
        {/* <h6 className="name_hotel">{retreat}</h6> */}
        <p>{shortDescription}</p>
        {/* <Link to={`#${id}`}>Discover</Link> */}
      </Grid>
      {/* <Grid className="cards heading">
        <h6>
          <SkeletonLoader count={1} />
        </h6>
        <h4>
          <SkeletonLoader count={1} />
        </h4>
        <p className="mb-0">
          <SkeletonLoader count={1} />
        </p>
        <p>
          <SkeletonLoader count={2} />
        </p>
        <Link to={`#${id}`}>
          <SkeletonLoader count={1} />
        </Link>
      </Grid> */}
    </div>
  );
}

// Content.propTypes = {
//   type: PropTypes.string.isRequired,
//   title: PropTypes.string.isRequired,
//   description: PropTypes.string.isRequired,
//   id: PropTypes.string.isRequired,
//   descriptionTitle: PropTypes.string,
// };

export default Content;
