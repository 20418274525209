import React from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { rightArrow } from "../../utils/svg.file";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

function Content({ category, location, handleJobDetailShow,designation, id,discription }) {
  const navigate = useNavigate();

  return (
    <>
      <div className="property-manager-div">
        <h4>{designation}</h4>
        <button className="read-more-btn" onClick={()=>{handleJobDetailShow({discription,category,location,designation})}}>
          Read more &#62;
        </button>
      </div>
      <div className="location-div">
        <p className="small-title">Location</p>
        <p className="post-name">{location}</p>
      </div>
      <div className="category-div">
        <p className="small-title">Category</p>
        <p className="post-name">{category}</p>
      </div>
      <div className="apply-btn-div">
        <Button className="dashed-btn" onClick={() => navigate(`/apply-job/${id}/${designation}/${location}`)}>
          Apply
        </Button>
      </div>
    </>
  );
}

export default Content;
