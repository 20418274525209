import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { cross } from "../../utils/svg.file";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function SuccessFullPaymentModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#D9CCC5",
    boxShadow: 24,
    border: "1px solid #707070",
    p: 4,
    width: 600,
    height: 500,
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (props.showSuccesfullModal) {
      setTimeout(() => {
        navigate("/digital-gift-card");
      }, 5000);
    }
  }, [props.showSuccesfullModal]);

  return (
    <>
      <div className="gift-modal">
        <Modal
          open={props.showSuccesfullModal}
          onClose={props.handleSuccessfullModalHide}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} textAlign="center">
            <Grid container justifyContent="center">
              <Grid item md={10}>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ minHeight: "400px" }}
                >
                  <div className="">
                    <h2>Thank you for your payment!</h2>
                    <p>
                      You will receive your Samadhi gift card shortly in your
                      email
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
    </>
  );
}
