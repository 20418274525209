import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { cross } from "../../utils/svg.file";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Button, Grid } from "@mui/material";
import { useState } from "react";
import SuccessFullPaymentModal from "./successfullModal";
export default function GiftConfirmModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#D9CCC5",
    boxShadow: 24,
    border: "1px solid #707070",
    p: 4,
    width: 600,
    heigt: 200,
  };
  const [showSuccesfullModal, setShowSuccesfullModal] = useState(false);

  const handleShowSuccessfullModal = () => {
    props.handleGiftcardBooking()
    setShowSuccesfullModal(true);
    props.handleGiftConfirmModalHide();
  };
  const handleSuccessfullModalHide = () => {
    setShowSuccesfullModal(false);
  };
  return (
    <>
      <div className="gift-modal">
        <Modal
          open={props.showConfirmModal}
          onClose={props.handleGiftConfirmModalHide}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} textAlign="center">
            <Grid container justifyContent="center">
              <Grid item md={8}>
                <div className="gift-modal-content">
                  <h5>
                    Please double check all details<br></br> on the Gift Card.
                  </h5>
                  <div className="mt-150 d-flex justify-content-center">
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox d />}
                        label="Accept T&C"
                      />
                    </FormGroup>
                  </div>
                  <div className="d-flex mt-40 justify-content-between">
                    <Button
                      className="white-btn"
                      style={{ border: "1px solid red!important" }}
                      onClick={props.handleGiftConfirmModalHide}
                    >
                      Back
                    </Button>
                    <Button
                      className="white-btn ms-5"
                      onClick={() => handleShowSuccessfullModal()}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <SuccessFullPaymentModal
          handleSuccessfullModalHide={handleSuccessfullModalHide}
          showSuccesfullModal={showSuccesfullModal}
        />
      </div>
    </>
  );
}
