import { React, useEffect, useState } from "react";
import SubHeader from "../../components/layout/SubHeader";
import { Button, Grid } from "@mui/material";
import { Container } from "@mui/system";
import IMAGES from "../../assets/images";
import OfferSlider from "../../components/OfferSlider";
import TestimonialSlider from "../../components/TestimonalSlider";
import Slider from "react-slick";
import { Link, useParams } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Base_URL } from "../../serverUrl";
import axios from "axios";
import swal from "sweetalert";
import AlertModal from "../../components/AlertModal";
import LoaderMain from "../../admin/component/loader";
import { Helmet } from "react-helmet";

export default function JobOpportunityApply() {
  let { id, designation, location } = useParams();

  const { jobBanner } = IMAGES;
  const [linkedIn, setLinkedIn] = useState("");
  const [facebook, setFacebook] = useState("");
  const [google, setGoogle] = useState("");
  const [file, setFile] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address_1, setAddress_1] = useState("");
  const [address_2, setAddress_2] = useState("");
  const [postcode, setPostCode] = useState("");
  const [country, setCountry] = useState("");
  const [aboutSamadhi, setAboutSamadhi] = useState("");
  const [alertMessageModal, setAlertMessageModal] = useState(false);
  const [combineErrorMessage, setCombineErrorMessage] = useState(null);
  const [isLoadingData, setIslodingData] = useState(true);

  const handleShowAlertModal = () => {
    setAlertMessageModal(true);
  };
  const handleAlertMessageHide = () => {
    setAlertMessageModal(false);
  };
  
  const submitjobs = () => {
    if(!firstName || !lastName || !email || !phoneNumber || !address_1 || !address_2){
      return setCombineErrorMessage('Please Fill require Fields')
    }
  
    var data = new FormData();
    data.append("job_id", id);
    data.append("linked_in", linkedIn);
    data.append("facebook", facebook);
    data.append("google", google);
    data.append("first_name", firstName);
    data.append("last_name", lastName);
    data.append("email", email);
    data.append("phone", phoneNumber);
    data.append("address_1", address_1);
    data.append("address_2", address_2);
    data.append("postcode", postcode);
    data.append("country", country);
    data.append("about", aboutSamadhi);
    data.append("cv", file);

    var config = {
      method: "post",
      url: `${Base_URL}profile`,
      headers: {
        Accept: "application/json",
        token: "barrer ",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        window.location.reload(false);
        handleShowAlertModal();
        setAboutSamadhi("");
        setAddress_1("");
        setAddress_2("");
        setCountry("");
        setEmail("");
        setFacebook("");
        setLinkedIn("");
        setGoogle("");
        setFirstName("");
        setFile("");
        setPhoneNumber("");
        setPostCode("");
        setCountry("");
      })
      .catch(function (error) {
        swal("failed!");
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIslodingData(false);
    }, 3000);
  }, [designation]);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [facebookError, setFacebookError] = useState(null);
  const [linkedInError, setLinkedInError] = useState(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = event => {
    if (!isValidEmail(event.target.value)) {
      setError('Email is invalid');
    } else {
      setError(null);
    }

    setEmail(event.target.value);
  };

  const handleChnageFacebookLink = (link) => {
    let result = link.split('.')[1]
    
    if(result === 'facebook') {

      setFacebook(link)
      setFacebookError('');
    } else {
      setFacebookError('Link is invalid');
    }
  }
  const handleChnageLinkedinLink = (link) => {
    let result = link.split('.')[1]
    
    if(result === 'linkedin') {

      setLinkedIn(link)
      setLinkedInError('');
    } else {
      setLinkedInError('Link is invalid');
    }
  }
  const fileupload = (e) => {
    var reader = new FileReader();

    reader.onloadend = function () {
      var file = e.target.files[0];
      // getBase64(file);
      setFile(file);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Boutique Hotels, Villas, Resorts & Residences | Samadhi Retreats</title>
        <meta name="description" content='Each Samadhi Retreats properties offer a unique sense of place, rooted in nature, culture and heritage. Discover more.'/>
    </Helmet>
    {isLoadingData ? <LoaderMain /> : '' }

      <div className=" job-form-page">
        {" "}
        <div className="main-bg">
          <div className="text-data-div">
            <Container maxWidth="xl">
              {" "}
              <Grid container spacing={4} justifyContent="center">
                <Grid item md={10}>
                  <div className="">
                    <h3 className="geting-text">
                      {designation}, {location}
                    </h3>
                    <h4>Candidate Profile </h4>
                    <p>Please be sure to fill out all required fields </p>{" "}
                    <p>* indicates a required field.</p>
                    {combineErrorMessage && (
                      <h2 style={{ color: "red" }}>{combineErrorMessage}</h2>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
          <Container maxWidth="xl">
            <Grid container spacing={4} justifyContent="center">
              <Grid item md={10} xs={12}>
                <div className="form-section">
                  <div className="form-card-div">
                    <h4>Social Media Profile</h4>
                    <input
                      className="job-input"
                      placeholder="Linkedin"
                      onChange={(e) => handleChnageLinkedinLink(e.target.value)}
                    />
                    {linkedInError && (
                      <h2 style={{ color: "red" }}>{linkedInError}</h2>
                    )}
                    <input
                      className="job-input"
                      placeholder="Facebook"
                      onChange={(e) => handleChnageFacebookLink(e.target.value)}
                    />
                    {facebookError && (
                      <h2 style={{ color: "red" }}>{facebookError}</h2>
                    )}
                    <input
                      className="job-input"
                      placeholder="Google"
                      onChange={(e) => setGoogle(e.target.value)}
                    />
                  </div>
                  <div className="form-card-div">
                    <div className="d-flex align-items-center">
                      <h4 className="m-0">CV</h4>
                      <div className="upload-btn-section">
                        <input
                          type="file"
                          onChange={fileupload}
                          accept="application/pdf, application/vnd.ms-excel"
                        />
                        <Button className="dashed-btn">Upload</Button>
                      </div>
                      <span className="ms-2 text-success">{file.name}</span>
                    </div>
                  </div>
                  <div className="form-card-div">
                    <h4>Your Information</h4>
                    <div className="d-flex">
                      {" "}
                      <input
                        className="job-input"
                        placeholder="First Name*"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <input
                        className="job-input"
                        placeholder="Last Name*"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                    <input
                      className="job-input"
                      placeholder="Email*"
                      type="email"
                      onChange={handleChange}
                    />
                    {error && <h2 style={{ color: "red" }}>{error}</h2>}
                    <input
                      className="job-input"
                      placeholder="Phone number*"
                      type="number"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <input
                      className="job-input"
                      placeholder="Address line 1*"
                      onChange={(e) => setAddress_1(e.target.value)}
                    />
                    <input
                      className="job-input"
                      placeholder="Address line 2"
                      onChange={(e) => setAddress_2(e.target.value)}
                    />
                    <div className="d-flex">
                      {" "}
                      <input
                        className="job-input"
                        placeholder="Postcode*"
                        onChange={(e) => setPostCode(e.target.value)}
                      />
                      <input
                        className="job-input"
                        placeholder="Country*"
                        onChange={(e) => setCountry(e.target.value)}
                      />
                    </div>{" "}
                    <input
                      className="job-input mt-4 w-50"
                      placeholder="How did you hear about Samadhi*"
                      onChange={(e) => setAboutSamadhi(e.target.value)}
                    />
                    <div className="d-flex justify-content-end mt-5">
                      <Button className="dashed-btn" onClick={submitjobs}>
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <AlertModal
          alertMessageModal={alertMessageModal}
          handleAlertMessageHide={handleAlertMessageHide}
          alertMessageContent={"Job Successfully Applied"}
          colorAlert="text-black"
        />
      </div>
    </>
  );
}
